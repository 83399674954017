import React, { useEffect, useState } from "react";
import { Box, Divider } from "@mui/material";
import Button from "@mui/material/Button";
import { DefaultCustomField, DynamicGroupRule } from "src/constants/types";
import Rule from "./Rule";

type RulesProps = {
  rules: DynamicGroupRule[] | undefined;
  customFields: DefaultCustomField[];
  saveRule: React.Dispatch<React.SetStateAction<DynamicGroupRule>>;
  deleteRule: React.Dispatch<React.SetStateAction<DynamicGroupRule>>;
  addNewRule: React.Dispatch<React.SetStateAction<void>>;
};

export default function Rules(props: RulesProps) {
  const { rules, customFields, saveRule, deleteRule, addNewRule } = props;
  const [addNewRuleForm, setAddNewRuleForm] = useState(false);
  const [editableRules, setEditableRules] = useState(
    rules && rules.length === 0
      ? [{ fieldName: "", operator: "", value: "" }]
      : rules
  );
  useEffect(() => {
    setAddNewRuleForm(false);
    setEditableRules(
      rules && rules.length === 0
        ? [{ fieldName: "", operator: "", value: "" }]
        : rules
    );
  }, [rules]);

  const removeRule = (rule: DynamicGroupRule) => {
    const filteredRules = editableRules?.filter(
      (r) => r.fieldName !== rule.fieldName
    );
    setEditableRules(filteredRules);
    deleteRule(rule);
  };

  return (
    <Box sx={{ marginTop: "10px", marginBottom: "10px" }}>
      <Box sx={{marginBottom: "20px"}}>
        {editableRules &&
          editableRules.map((r, index) => (
            <Box key={r.fieldName} sx={{ marginTop: "10px" }}>
              <Rule
                rules={rules}
                isOnlyRule={editableRules?.length === 1}
                customFields={customFields}
                saveRule={saveRule}
                deleteRule={removeRule}
                rule={r}
              />
              <Box sx={{
                textAlign: 'center',
                marginTop: '10px',
              }}>
                {((rules?.length || 0) > index + 1) && <Divider textAlign="center">And</Divider>}
              </Box>
            </Box>
          ))}
        {addNewRuleForm && (
          <Rule
            rules={rules}
            isOnlyRule={editableRules?.length === 1}
            customFields={customFields}
            saveRule={saveRule}
            deleteRule={removeRule}
            rule={{ fieldName: "", operator: "", value: "" }}
          />
        )}
      </Box>
      <Box textAlign="center" sx={{marginBottom: '30px'}}>
        <Button
          variant="contained"
          disabled={rules?.some(
            (m) => m.fieldName === "" || m.operator === "" || m.value === ""
          )}
          style={{ width: "150px" }}
          onClick={() => {
            addNewRule();
          }}
        >
          Add New Rule
        </Button>
        {addNewRuleForm && (
          <Button
            sx={{ marginLeft: "10px" }}
            variant="contained"
            color={"secondary"}
            style={{ width: "150px" }}
            onClick={() => setAddNewRuleForm(false)}
          >
            Cancel
          </Button>
        )}
      </Box>
    </Box>
  );
}
