import React, { useState, useEffect, useRef } from "react";
import MainWrapper from "src/components/MainWrapper/view";
import { PageHeader, HeaderContainer } from "./styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Button from "@mui/material/Button";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useGetGroupsApi } from "src/api/contacts";
import { useSelector } from "react-redux";
import {
  selectCompanies,
  selectedCompany,
} from "src/store/company/companySlice";
import CreateGroupModal from "./CreateGroupModal/view";
import CreateContactModal from "./CreateContactModal/view";
import { getDateTimeUSAFormat } from "src/constants/helpers";
import EditIcon from "@mui/icons-material/Edit";
import { columns, customFieldsLimit, itemsPerPage } from "./costants";
import { useArchiveUnarchiveGroupApi } from "src/api/contacts";
import { CustomField, Group } from "src/constants/types";
import { useNavigate } from "react-router-dom";
import useConfirmationDialog from "src/components/ConfirmationDialog/view";
import DownloadCsvModal from "./DownloadCsvModal/view";
import { useGetCustomFieldsApi } from "src/api/customFields";
import SearchInput from "../../components/SearchInput/view";
import SortIcon from "../../components/SortIcon/view";
import { GetGroupsParams } from "../../api/contacts/types";
import {
  Box,
  FormControl,
  Stack,
  TableFooter,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import { useHandleSelectedCompany } from "src/hooks/useHandleSelectedCompany";

function Groups() {
  const [page, setPage] = useState(0);
  const tableRef = useRef<HTMLTableElement>(null);
  const [sortField, setSortField] = useState<string>("createdAt");
  const [sortDir, setSortDir] = useState<"asc" | "desc">("desc");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [groups, setGroups] = useState<{
    count: number;
    items: Group[] | null;
  }>({ count: 0, items: null });
  const [editableGroup, setEditableGroup] = useState<Group | undefined>();
  const getGroups = useGetGroupsApi();
  const toggleArchiveGroup = useArchiveUnarchiveGroupApi();
  const navigate = useNavigate();
  const getCustomFields = useGetCustomFieldsApi();
  const selectedCompanyId: string | undefined = useSelector(selectedCompany);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    tableRef?.current?.scrollIntoView();
  };
  const [showCreateGroupModal, setCreateGroupModal] = useState(false);
  const [showCreateContactModal, setCreateContactModal] = useState(false);
  const [showDownloadCsvModal, setShowDownloadCsvModal] = useState(false);
  const [customFields, setCustomFields] = useState<CustomField[] | undefined>();
  const [showArchivedGroups, setShowArchivedGroups] = useState(false);
  useHandleSelectedCompany();
  useEffect(() => {
    refreshGroups();
  }, [showArchivedGroups]);

  const refreshGroups = () => {
    setEditableGroup(undefined);
    if (selectedCompanyId) {
      window.setTimeout(() => {
        const reqQuery: GetGroupsParams = {
          companyId: selectedCompanyId,
          limit: itemsPerPage,
          offset: page * itemsPerPage,
          sort: `${getSortFieldForApi(sortField)}_${sortDir}`,
          statuses: showArchivedGroups ? "active,archived" : "active",
        };
        if (searchQuery) {
          reqQuery.query = searchQuery;
        }
        getGroups(reqQuery)
          .then(({ data }) => {
            setGroups(data);
            tableRef?.current?.scrollIntoView();
          })
          .catch((e) => {
            console.log(e);
          });
      }, 300);
    }
  };
  const { openDialog, ConfirmationDialog } = useConfirmationDialog();

  const formatTableRows = (groups: Group[]) => {
    return groups.map(
      ({
        id,
        name,
        description,
        type,
        createdAt,
        updatedAt,
        validContactCount,
        invalidContactCount,
        status,
      }) => {
        return {
          id,
          name,
          description,
          type,
          status,
          validContacts: validContactCount,
          invalidContacts: invalidContactCount,
          createdAt: getDateTimeUSAFormat(new Date(createdAt)),
          updatedAt: getDateTimeUSAFormat(new Date(updatedAt)),
          actions: "edit/delete",
        };
      }
    );
  };

  const mapSortPropsToApi: Record<string, string> = {
    name: "name",
    type: "type",
    validContacts: "valid_contacts",
    createdAt: "date_created",
    updatedAt: "date_updated",
  };

  const isFieldSortable = (fieldName: string) =>
    Object.prototype.hasOwnProperty.call(mapSortPropsToApi, fieldName);

  const getSortFieldForApi = (fieldName: string) => {
    return mapSortPropsToApi[fieldName];
  };

  const swapSortDirection = (dir?: "asc" | "desc") => {
    if (dir) {
      setSortDir(dir);
    } else {
      setSortDir(sortDir == "asc" ? "desc" : "asc");
    }
  };

  const changeSortField = (fieldName: string) => {
    if (sortField == fieldName) {
      swapSortDirection();
    } else {
      swapSortDirection("desc");
    }
    setSortField(fieldName);
  };

  const handleSearchQuery = (text: string) => {
    setSearchQuery(text);
  };

  useEffect(() => {
    refreshGroups();
  }, [
    page,
    selectedCompanyId,
    selectCompanies,
    sortDir,
    sortField,
    searchQuery,
  ]);

  useEffect(() => {
    setPage(0);
    tableRef?.current?.scrollIntoView();
  }, [sortDir, sortField, searchQuery]);

  const toggleGroupArchiveStatus = (
    groupId: string,
    name: string,
    description: string,
    status: string = "archived"
  ) => {
    selectedCompanyId &&
      toggleArchiveGroup(
        groupId,
        name,
        description,
        selectedCompanyId,
        status
      ).then(() => {
        refreshGroups();
      });
  };

  const handleContactAdd = async () => {
    const customFields = await getCustomFields({
      companyId: selectedCompanyId!,
      offset: 0,
      limit: customFieldsLimit,
    });
    if (customFields.data.items) {
      setCustomFields(customFields.data.items);
    }
    setCreateContactModal(true);
  };

  const handleGroupEdit = (groupId: string) => {
    groups?.items &&
      setEditableGroup(groups.items?.find((item) => item.id === groupId));
    setCreateGroupModal(true);
  };

  return (
    <MainWrapper>
      <PageHeader>Contact Groups</PageHeader>
      <HeaderContainer>
        <SearchInput handleSearch={handleSearchQuery} />
        <Box>
          <Button
            variant="contained"
            style={{ marginRight: "10px" }}
            onClick={handleContactAdd}
          >
            Add Contacts
          </Button>
          <Button
            variant="contained"
            style={{ marginRight: "10px" }}
            onClick={() => {
              setShowDownloadCsvModal(true);
            }}
          >
            Import Contacts
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setCreateGroupModal(true);
              setEditableGroup(undefined);
            }}
          >
            Create Group
          </Button>
        </Box>
      </HeaderContainer>
      {showCreateContactModal && (
        <CreateContactModal
          customFields={customFields}
          show={showCreateContactModal}
          setShowModal={setCreateContactModal}
          refresh={refreshGroups}
        />
      )}
      {showCreateGroupModal && (
        <CreateGroupModal
          show={showCreateGroupModal}
          setShowModal={setCreateGroupModal}
          refreshGroups={refreshGroups}
          groupData={editableGroup}
        />
      )}
      {showDownloadCsvModal && (
        <DownloadCsvModal
          show={showDownloadCsvModal}
          setShowModal={setShowDownloadCsvModal}
          refreshGroups={refreshGroups}
        />
      )}
      <Paper sx={{ width: "100%", overflow: "hidden", marginTop: "20px" }}>
        <TableContainer sx={{ height: "calc(100vh - 290px)" }}>
          <Table ref={tableRef} stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    onClick={() =>
                      isFieldSortable(column.id)
                        ? changeSortField(column.id)
                        : {}
                    }
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      background: "#254597",
                      color: "#fff",
                      cursor: isFieldSortable(column.id)
                        ? "pointer"
                        : "initial",
                    }}
                  >
                    {column.label}
                    {sortField == column.id && <SortIcon dir={sortDir} />}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {groups?.items &&
                formatTableRows(groups?.items).map((row, index) => {
                  return (
                    <TableRow
                      key={index}
                      sx={{ cursor: "pointer" }}
                      hover
                      onClick={() => {
                        navigate(`/group/${row.id}`);
                      }}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <>
                              {column.id === "actions" ? (
                                row.type !== "default" ? (
                                  <>
                                    <Button
                                      sx={{ minWidth: 0 }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleGroupEdit(row.id);
                                      }}
                                      disabled={row.type === "default"}
                                    >
                                      <EditIcon />
                                    </Button>
                                    {row.status === "active" && (
                                      <Button
                                        sx={{ minWidth: 0 }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          openDialog({
                                            title: `Archive "${row.name}"?`,
                                            content:
                                              "Are you sure you want to archive this group?",
                                            onConfirm: () => {
                                              toggleGroupArchiveStatus(
                                                row.id,
                                                row.name,
                                                row.description
                                              );
                                            },
                                          });
                                        }}
                                        disabled={row.type === "default"}
                                      >
                                        <ArchiveIcon />
                                      </Button>
                                    )}
                                    {row.status === "archived" && (
                                      <Button
                                        sx={{ minWidth: 0 }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          openDialog({
                                            title: `Unarchive "${row.name}"?`,
                                            content:
                                              "Are you sure you want to unarchive this group?",
                                            onConfirm: () => {
                                              toggleGroupArchiveStatus(
                                                row.id,
                                                row.name,
                                                row.description,
                                                "active"
                                              );
                                            },
                                          });
                                        }}
                                        disabled={row.type === "default"}
                                      >
                                        <UnarchiveIcon />
                                      </Button>
                                    )}
                                  </>
                                ) : null
                              ) : null}
                              {column.id === "type" &&
                                (value === "custom"
                                  ? "Standard"
                                  : row.type === "default"
                                  ? "Default"
                                  : "Dynamic")}
                              {column.id === "name" && (
                                <>
                                  {row.status === "archived" ? (
                                    <>
                                      {value} <b>(Archive)</b>
                                    </>
                                  ) : (
                                    `${value}`
                                  )}
                                </>
                              )}
                              {column.id !== "actions" &&
                                column.id !== "type" &&
                                column.id !== "name" &&
                                value}
                            </>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TableFooter>
          <Stack
            sx={{ marginTop: "10px", marginLeft: "10px" }}
            width="100%"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box sx={{ fontSize: "14px" }}>Archived Groups: </Box>
            <FormControl>
              <ToggleButtonGroup
                sx={{ height: "25px", zIndex: "999" }}
                size="small"
                color="primary"
                value={showArchivedGroups}
                exclusive
                onChange={() => {
                  setShowArchivedGroups(!showArchivedGroups);
                }}
              >
                <ToggleButton value={false} selected={showArchivedGroups}>
                  Show
                </ToggleButton>
                <ToggleButton value={true} selected={!showArchivedGroups}>
                  Hide
                </ToggleButton>
              </ToggleButtonGroup>
            </FormControl>
          </Stack>
        </TableFooter>
        <TablePagination
          sx={{ marginTop: "-40px" }}
          component="div"
          count={groups.count}
          rowsPerPageOptions={[-1]}
          rowsPerPage={itemsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      </Paper>
      <ConfirmationDialog />
    </MainWrapper>
  );
}

export default Groups;
