import React, {useState} from "react";
import {SearchInputType} from "./styles";
import SearchIcon from "@mui/icons-material/Search";
import {Button, TextField} from "@mui/material";

const SearchInput = ({handleSearch, label}: {
  handleSearch: (text: string) => void;
  label?: string;
}) => {
  const [text, setText] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setText(value);
    handleSearch(value);
  };

  return <SearchInputType>
    <TextField
      label={label ? label : 'Search'}
      name="text"
      style={{ paddingRight: "50px" }}
      value={text}
      size='small'
      onChange={handleChange}
      InputProps={{
        style: { paddingRight: '50px' },
        endAdornment: (
          <>
            <Button
              sx={{
                height: '100%',
                width: "50px",
                minWidth: "50px",
                padding: 0,
                position: "absolute",
                right: "0",
              }}
              style={{backgroundColor: "transparent", cursor: "pointer"}}
              onClick={() => handleSearch(text)}
            >
              <SearchIcon/>
            </Button>
          </>
        ),
      }}
    />
    {/*<SearchIcon/>*/}
  </SearchInputType>
};

export default SearchInput;
