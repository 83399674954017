import React, { useState } from "react";
import { useSelector } from "react-redux";
import MainWrapper from "src/components/MainWrapper/view";
import { selectProfile } from "src/store/profile/profileSlice";
import AccessDenied from "../AccessDenied";
import { Tabs, Tab, Box, IconButton, Stack, Typography } from "@mui/material";
import BhiTable from "src/components/Table/view";
import {
  useDeleteAdminCompanyApi,
  useGetAdminCompaniesApi,
  useGetAdminCompanyApi,
} from "src/api/adminCompanies";
import { useHandleSelectedCompany } from "src/hooks/useHandleSelectedCompany";
import { AdminCompany } from "src/constants/types";
import { GridColDef } from "@mui/x-data-grid";
import toast from "react-hot-toast";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import useConfirmationDialog from "src/components/ConfirmationDialog/view";

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
};

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

const Companies = () => {
  const [tab, setTab] = useState(0);
  const { profile } = useSelector(selectProfile);
  const getCompanies = useGetAdminCompaniesApi();
  const { openDialog, ConfirmationDialog } = useConfirmationDialog();
  const deleteCompany = useDeleteAdminCompanyApi();
  const getCompany = useGetAdminCompanyApi();
  useHandleSelectedCompany();
  const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
    setTab(newTab);
  };

  const initialCompany: AdminCompany = {
    id: "",
    name: "",
    messagingProfileId: "",
    createMessageProfile: true,
    tcrCampaignId: "",
    status: "",
    validContacts: 0,
    phoneNumber: "",
    phoneNumbers: [],
  };

  const formatColumns = (
    handleEditItem: (id: string) => void,
    handleDeleteItem: (id: string) => void
  ) => {
    const columns: GridColDef[] = [
      {
        field: "name",
        headerName: "Company",
        sortable: true,
        width: 300,
        editable: false,
        renderCell: (row) => {
          return (
            <Stack direction="column" height="100%" justifyContent="center">
              <Typography
                sx={{
                  fontSize: "12px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {row.formattedValue}
              </Typography>
              <Typography fontSize={12} color="#ccc" sx={{ marginTop: "5px" }}>
                <Stack direction="row" justifyContent="space-between">
                  <Box
                    sx={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {row.id}
                  </Box>
                  {row.id && (
                    <IconButton
                      size="small"
                      sx={{
                        transform: "scale(0.7)",
                        height: "3px",
                        width: "3px",
                        color: "#264597",
                        marginLeft: "15px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigator.clipboard.writeText(row.id?.toString());
                        toast.success("copied to clipboard");
                      }}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  )}
                </Stack>
              </Typography>
            </Stack>
          );
        },
      },
      {
        field: "status",
        headerName: "Status",
        width: 70,
        sortable: false,
        editable: false,
      },
      {
        field: "phoneNumbers",
        width: 150,
        headerName: "Phone Numbers",
        valueGetter: (value, row) => row.phoneNumbers,
        renderCell: (row) => {
          if (!row.formattedValue) {
            return "";
          }
          if (Array.isArray(row.formattedValue)) {
            return row.formattedValue.map((m) => m.number).join(",");
          }
        },
        sortable: false,
        editable: false,
      },
      {
        field: "messagingProfileId",
        headerName: "Messaging Profile ID",
        sortable: false,
        width: 260,
        editable: false,
        renderCell: (row) => {
          return (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              height="100%"
              width="100%"
            >
              <Box
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {row.formattedValue}
              </Box>
              {row.formattedValue && (
                <IconButton
                  size="small"
                  sx={{
                    transform: "scale(0.7)",
                    color: "#264597",
                    marginLeft: "15px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      row.formattedValue?.toString()
                    );
                    toast.success("copied to clipboard");
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              )}
            </Stack>
          );
        },
      },
      {
        field: "tcrCampaignId",
        headerName: "TCR Campaign ID",
        sortable: false,
        width: 150,
        editable: false,
        renderCell: (row) => {
          return (
            <Stack direction="row" justifyContent="space-between">
              <Box
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {row.formattedValue}
                {row.formattedValue && (
                  <IconButton
                    size="small"
                    sx={{
                      transform: "scale(0.7)",
                      color: "#264597",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        row.formattedValue?.toString()
                      );
                      toast.success("copied to clipboard");
                    }}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                )}
              </Box>
              <Box
                className="bhiTableActions"
                sx={{
                  position: "absolute",
                  right: "5px",
                  zIndex: 99,
                  display: "none",
                }}
              >
                <IconButton
                  sx={{
                    color: "#264597",
                    borderRadius: "50%",
                    marginLeft: "10px",
                    transform: "scale(0.7)",
                    backgroundColor: "transparent",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEditItem(row.row.id);
                  }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  sx={{
                    color: "#264597",
                    transform: "scale(0.7)",
                    borderRadius: "50%",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    openDialog({
                      title: `Delete ${row.row.name}`,
                      content: "Are you sure you want to delete this company?",
                      onConfirm: () => {
                        handleDeleteItem(row.row.id);
                      },
                    });
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Stack>
          );
        },
      },
    ];

    return columns;
  };

  const getProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  if (!profile) {
    return null;
  }

  const isBhiAdmin = profile?.type === "admin";

  if (!isBhiAdmin) {
    return <AccessDenied />;
  }

  return (
    <MainWrapper>
      <Box sx={{ width: "100%" }}>
        <Tabs value={tab} onChange={handleTabChange} aria-label="companies tab">
          <Tab label="Companies" {...getProps(0)} />
        </Tabs>
        <CustomTabPanel value={tab} index={0}>
          <Box sx={{ width: "100%" }}>
            <BhiTable<AdminCompany>
              tableName="companies"
              formatColumns={formatColumns}
              fetchData={getCompanies}
              getItem={getCompany}
              deleteItem={deleteCompany}
              initialData={initialCompany}
              pageSize={100}
            />
          </Box>
        </CustomTabPanel>
      </Box>
      <ConfirmationDialog />
    </MainWrapper>
  );
};

export default Companies;
