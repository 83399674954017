import React, { useState, useContext } from "react";
import { StyledHeader } from "./styles";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { AuthContext } from "src/contexts/AuthContext";
import {
  selectedCompany,
  selectCompanies,
} from "src/store/company/companySlice";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedCompany } from "src/store/company/companySlice";
import { useNavigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { selectProfile } from "src/store/profile/profileSlice";
import TextField from "@mui/material/TextField";
import { ClickAwayListener, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

function Header() {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [searchCompanyTerm, setSearchCompanyTerm] = useState<string>("");
  const [open, setOpen] = React.useState(false);
  const authContext = useContext(AuthContext);
  const selectedCompanyId =
    localStorage.getItem("bhiCurrentCompany") ?? useSelector(selectedCompany);
  const { profile } = useSelector(selectProfile);
  const isBhiAdmin = profile?.type === "admin";
  const companies = useSelector(selectCompanies);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleLogout = () => {
    authContext?.setIsAuthenticated(false);
    localStorage.removeItem("a_token");
    localStorage.removeItem("r_token");
    localStorage.removeItem("bhiRefreshingToken");
    localStorage.removeItem("bhiCurrentCompany");
    dispatch({ type: "logout" });
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleChange = (companyId: string) => {
    localStorage.setItem("bhiCurrentCompany", companyId);
    window.location.reload();
  };

  return (
    <StyledHeader>
      {selectedCompanyId && (
        <ClickAwayListener
          onClickAway={() => {
            setOpen(false);
          }}
        >
          <FormControl
            style={{ width: "200px", marginRight: "20px" }}
            variant="standard"
          >
            <Select
              onClick={() => {
                setOpen(!open);
              }}
              labelId="demo-simple-select-label"
              id="bhi-select-company"
              value={selectedCompanyId}
              open={open}
              MenuProps={{disablePortal: true}}
              defaultValue={selectedCompanyId}
              label="Company"
            >
              <MenuItem
                sx={{
                  "&:hover": { backgroundColor: "white" },
                  paddingRight: 0,
                }}
                onKeyDown={(e) => e.stopPropagation()}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                }}
              >
                <TextField
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      height: "35px",
                      width: "100%",
                      paddingTop: 0,
                      paddingBottom: 0,
                    },
                    marginLeft: "-8px",
                  }}
                  id="nested-select-filter"
                  placeholder="Search..."
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={searchCompanyTerm}
                  autoComplete="off"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{ marginLeft: "-10px" }}
                      >
                        <SearchIcon fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{ shrink: false }}
                  onChange={(e) => {
                    setSearchCompanyTerm(e.target.value);
                  }}
                />
              </MenuItem>
              {companies &&
                [...companies]
                  .filter(
                    (c) =>
                      c.name.includes(searchCompanyTerm) ||
                      c.id === selectedCompanyId
                  )
                  .sort((a, b) =>
                    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                  )
                  .map(({ id, name }) => {
                    return (
                      <MenuItem
                        onClick={() => {
                          setOpen(false);
                          setSelectedCompany(id);
                          handleChange(id);
                        }}
                        value={id}
                        key={id}
                      >
                        {name}
                      </MenuItem>
                    );
                  })}
            </Select>
          </FormControl>
        </ClickAwayListener>
      )}
      <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
        <AccountCircleIcon sx={{ fontSize: 40 }} />
      </IconButton>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem key={"profile"} onClick={() => navigate("/profile")}>
          <Typography textAlign="center">{"My Profile"}</Typography>
        </MenuItem>
        {isBhiAdmin && (
          <MenuItem
            key={"companies"}
            onClick={() => navigate("/admin/companies")}
          >
            <Typography textAlign="center">{"Admin"}</Typography>
          </MenuItem>
        )}
        <MenuItem key={"logout"} onClick={handleLogout}>
          <Typography textAlign="center">{"Log out"}</Typography>
        </MenuItem>
      </Menu>
    </StyledHeader>
  );
}

export default Header;
