import React, { Fragment, SyntheticEvent, useEffect, useState } from "react";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import { Box, FormControl } from "@mui/material";
import { useAssignContactsApi, useGetGroupsApi, useRemoveContactsApi } from "src/api/contacts";
import { selectedCompany } from "src/store/company/companySlice";
import { useSelector } from "react-redux";
import { ImportContactsModalStep } from "../costants";
import SelectWithSearch from "src/components/SelectWithSearch/view";
import { Group } from "src/constants/types";

type AddContactsToGroupsModalType = {
  error: boolean;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
  createdContactsIds: string[];
  existedContactsIds: string[];
  setImportContactsStep: React.Dispatch<
    React.SetStateAction<ImportContactsModalStep>
  >;
  handleClose: () => void;
  setModalTitle: (text: string) => void;
};

export default function AddContactsToGroups({
  createdContactsIds,
  existedContactsIds,
  setModalTitle,
  error,
  setError,
  handleClose,
}: AddContactsToGroupsModalType) {
  const selectedCompanyId = useSelector(selectedCompany);
  const [selectedGroupIds, setSelectedGroupIds] = useState<string[] | null>(null);
  const [groups, setGroups] = useState<Group[]>();
  const getGroups = useGetGroupsApi();

  useEffect(() => {
    selectedCompanyId &&
      getGroups({
        companyId: selectedCompanyId,
        limit: 1000,
        offset: 0,
      })
        .then(({ data }) => {
          setGroups(data.items.filter((g) => g.type !== "default"));
        })
        .catch((e) => {
          console.log(e);
        });
  }, [selectedCompanyId]);

  useEffect(() => {
    setModalTitle(`Add ${createdContactsIds.length + (existedContactsIds?.length || 0)} imported contact${createdContactsIds.length > 1 ? 's' : ''} to group(s)`)
  }, []);


  const assignContacts = useAssignContactsApi();
  const removeContactsFromGroup = useRemoveContactsApi();

  const handleSelectedGroupsChange = (
    _: SyntheticEvent<Element, Event>,
    items: { text: string; value: string }[]
  ) => {
    const ids = items?.map(({ value }) => value) || [];
    setSelectedGroupIds(ids);
  };


  const assignContactsToGroups = async (contacts: string[]) => {
    if (!contacts || contacts.length === 0) {
      return;
    }

    const promises = [];
    for (const groupId of selectedGroupIds!) {
      promises.push(
        assignContacts(contacts, groupId, selectedCompanyId as string)
      );
    }

    try {
      await Promise.all(promises);
    } catch (e) {
      console.log(e);
        if (e.error === "validation") {
          setError(true);
        }
    }
  };

  const changeContactsGroups = async (contacts: string[]) => {
    const promises = [];

    if (!contacts || contacts.length === 0) {
      return;
    }

    for (const groupId of selectedGroupIds!) {
      promises.push(
        removeContactsFromGroup(contacts, groupId, selectedCompanyId as string)
      );
    }

    try {
      await Promise.all(promises);
      await assignContactsToGroups(contacts);
      handleClose();
    } catch (e) {
      console.log(e);
      if (e.error === "validation") {
        setError(true);
      }
    }
  };

  return (
    <Fragment>
      <FormControl sx={{ m: 1, width: "100%", margin: "0 0 20px 0" }}>
        <SelectWithSearch
          label="Add to group(s) (optional)"
          placeholder="Add to group(s) (optional)"
          options={
            groups?.filter(g => g.type === "custom").map(({ name, id }) => ({
              text: name,
              value: id,
            })) || []
          }
          values={selectedGroupIds || []}
          handleSelectChange={(e, items) =>
            handleSelectedGroupsChange(e, items)
          }
          style={{ width: "100%", maxHeight: "100px" }}
        />
      </FormControl>

      {error && (
        <FormLabel
          error={true}
          style={{ marginTop: "10px", textAlign: "center" }}
        >
          {"Unable to add contacts to group contacts."}
        </FormLabel>
      )}
      <Box sx={{ display: 'flex', width: "100%" }} justifyContent={"end"}>
        <Button variant="contained" style={{marginRight: "10px"}} color="secondary" onClick={handleClose}>
          Skip
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            assignContactsToGroups(createdContactsIds).then(() => {
              changeContactsGroups(existedContactsIds);
            })
          }}
          disabled={!selectedGroupIds?.length || (!createdContactsIds?.length && !existedContactsIds?.length) }
        >
          Add To Group(s)
        </Button>
      </Box>
    </Fragment>
  );
}
