import styled from 'styled-components';

export const StyledHeader = styled.div`
    position: fixed;
    top: 0;
    right: 2%;
    width: 100%;
    display: flex;
    justify-content: end;
    height: 80px;
    z-index: 999;
    align-items: center;
`;