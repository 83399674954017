import React, { useState } from "react";
import {
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";
import { DefaultCustomField, DynamicGroupRule } from "src/constants/types";
import DeleteIcon from "@mui/icons-material/Delete";

const ruleOperators: Record<string, string> = {
  equal: "=",
  "not equal": "!=",
  "≤": "<=",
  "<": "<",
  "≥": ">=",
  ">": ">",
  "is empty": "is_null",
  "is not empty": "is_not_null",
};

type RuleProps = {
  rule: DynamicGroupRule;
  rules: DynamicGroupRule[] | undefined;
  customFields: DefaultCustomField[];
  isOnlyRule: boolean;
  saveRule: React.Dispatch<React.SetStateAction<DynamicGroupRule>>;
  deleteRule: React.Dispatch<React.SetStateAction<DynamicGroupRule>>;
};

export default function Rule(props: RuleProps) {
  const { rule, customFields, saveRule, deleteRule, isOnlyRule, rules } = props;
  const [editableRule, setEditableRule] = useState<DynamicGroupRule>(rule);

  const updateRule = () => {
    if (editableRule && editableRule.fieldName && editableRule.operator) {
      if (
        editableRule.operator === "is_null" ||
        editableRule.operator === "is_not_null"
      ) {
        saveRule(editableRule);
      } else if (editableRule.value) {
        saveRule(editableRule);
      }
    }
  };

  const handleChangeRule = ({ target }: SelectChangeEvent<string>) => {
    if (editableRule) {
      setEditableRule({ ...editableRule, fieldName: target.value });
      if (editableRule.id) {
        saveRule({ ...editableRule, fieldName: target.value });
      }
    }
  };

  const handleChangeRuleValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (editableRule) {
      setEditableRule({ ...editableRule, value: e.target.value });
    }
  };

  const handleOperatorChange = ({ target }: SelectChangeEvent<string>) => {
    if (editableRule) {
      const ruleValue =
        target.value === "is_null" || target.value === "is_not_null"
          ? "null"
          : "";
      setEditableRule({
        ...editableRule,
        operator: target.value,
        value: ruleValue,
      });
      if (editableRule.id) {
        saveRule({
          ...editableRule,
          operator: target.value,
          value: ruleValue,
        });
      }
    }
  };

  if (!editableRule) {
    return null;
  }

  const filteredCustomFields = rules
    ? customFields.map((c) => {
        const formattedRule: DefaultCustomField = { ...c, disabled: true };
        if (
          !rules.map((r) => r.fieldName).includes(c.name) ||
          editableRule.fieldName === c.name
        ) {
          formattedRule.disabled = false;
        }
        return formattedRule;
      })
    : customFields;
  return (
    <>
      <Stack width="100%" direction="row" gap="5px">
        <Select
          sx={{ width: "45%", height: "35px" }}
          MenuProps={{ disableScrollLock: true }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          size="small"
          value={editableRule.fieldName}
          onChange={handleChangeRule}
          name="fieldName"
        >
          {filteredCustomFields
            .sort((a, b) => a.description.toLowerCase().localeCompare(b.description.toLowerCase()))
            .map((c) => (
              <MenuItem disabled={c.disabled} value={c.name} key={c.id}>
                {c.description}
              </MenuItem>
            ))}
        </Select>
        <Select
          sx={{ width: "25%", height: "35px" }}
          MenuProps={{ disableScrollLock: true }}
          labelId="demo-simple-select-label"
          id="demo-simple-select2"
          size="small"
          value={editableRule.operator}
          onChange={handleOperatorChange}
          onBlur={updateRule}
          name="operator"
        >
          {Object.keys(ruleOperators).map((key, index) => (
            <MenuItem value={ruleOperators[key]} key={index}>
              {key}
            </MenuItem>
          ))}
        </Select>
        <TextField
          sx={{
            padding: 0,
            width: "30%",
            "& .MuiOutlinedInput-input": {
              height: "35px",
              paddingTop: 0,
              paddingBottom: 0,
            },
            "& .MuiInputBase-input.Mui-disabled": {
              backgroundColor: "#ccc",
              borderRadius: "4px",
            },
          }}
          id="outlined-basic"
          name="value"
          disabled={
            editableRule.operator === "is_null" ||
            editableRule.operator === "is_not_null"
          }
          variant="outlined"
          value={
            editableRule.operator === "is_null" ||
            editableRule.operator === "is_not_null"
              ? ""
              : editableRule.value
          }
          onChange={handleChangeRuleValue}
          onBlur={updateRule}
        />
        {!isOnlyRule && (
          <IconButton
            sx={{
              color: "#264597",
              borderRadius: "50%",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
            className="action"
            onClick={(e) => {
              e.stopPropagation();
              if (editableRule) {
                deleteRule(editableRule);
              }
            }}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </Stack>
    </>
  );
}
