import React, { Fragment, useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import { ContentContainer } from "./styles";
import Button from "@mui/material/Button";
import {
  addMessageTemplateValidationSchema,
  ValidationErrors,
} from "src/constants/validations";
import { DefaultCustomField, MessageTemplate } from "src/constants/types";
import * as Yup from "yup";
import { Box, ClickAwayListener, Menu, MenuItem, Stack } from "@mui/material";
import {
  useCreateMessageTemplateApi,
  useUpdateMessageTemplateApi,
} from "src/api/messageTemplates";
import { useSelector } from "react-redux";
import { selectedCompany } from "src/store/company/companySlice";
import EmojiPicker, { EmojiClickData } from "emoji-picker-react";
import HighlightWithinTextarea from "react-highlight-within-textarea";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import { selectProfile } from "src/store/profile/profileSlice";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    overflowY: "visible",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    width: "700px",
    height: "700px",
  },
}));

type CreateCompanyModalType = {
  show: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  initialMessageTemplate: MessageTemplate;
  messageTemplate: MessageTemplate;
  metadata: DefaultCustomField[] | undefined;
  refreshMessageTemplates: () => void;
};

export default function CreateMessageTemplateModal({
  show,
  setShowModal,
  initialMessageTemplate,
  messageTemplate,
  refreshMessageTemplates,
  metadata,
}: CreateCompanyModalType) {
  const [errors, setErrors] = useState<ValidationErrors>({});
  const selectedCompanyId = useSelector(selectedCompany);
  const createMessageTemplate = useCreateMessageTemplateApi();
  const updateMessageTemplate = useUpdateMessageTemplateApi();
  const { profile } = useSelector(selectProfile);
  const messageInputRef = useRef<HTMLTextAreaElement>(null);
  const [openEmojiModal, setOpenEmojiModal] = useState<boolean>(false);
  const [openMetadata, setOpenMetadata] = useState<boolean | null>(null);
  const [formValues, setFormValues] =
    useState<MessageTemplate>(messageTemplate);
  useEffect(() => {
    setFormValues(formValues.id ? formValues : messageTemplate);
  }, [messageTemplate]);

  const insertMetadata = (name: string) => {
    const start = messageInputRef.current?.value.substring(
      0,
      messageInputRef.current.selectionStart
    );
    const end = messageInputRef.current?.value.substring(
      messageInputRef.current.selectionStart
    );
    const text =
      (start?.endsWith("{{")
        ? messageInputRef.current?.value.substring(
            0,
            messageInputRef.current.selectionStart - 2
          )
        : start) +
      `{{` +
      name +
      `}}` +
      end;
    setFormValues({
      ...formValues,
      text: text,
    });

    messageInputRef.current?.setSelectionRange(
      messageInputRef.current?.selectionStart,
      messageInputRef.current?.selectionStart + 1
    );
    messageInputRef.current?.focus();
    setOpenMetadata(false);
  };

  const handleMetadataMenuClose = () => {
    setOpenMetadata(false);
  };

  const isShowMetadataMenu = (inputElement: HTMLTextAreaElement) => {
    const value = inputElement.value;
    const caretPosition = inputElement.selectionStart;
    const lastChars = value.substring(caretPosition - 2, caretPosition);

    return lastChars;
  };

  const isShowEmodjiMenu = (inputElement: HTMLTextAreaElement) => {
    const value = inputElement.value;
    const caretPosition = inputElement.selectionStart;
    if (value && value.length === 1 && value === ":") {
      return true;
    }
    if (value.substring(caretPosition - 2, caretPosition) === " :") {
      return true;
    }
    return false;
  };

  const handleClose = () => {
    setShowModal(false);
    setFormValues(initialMessageTemplate);
    setErrors({});
  };

  const handleMessageTemplateSave = () => {
    const isValid = validate(formValues);
    if (isValid && selectedCompanyId) {
      if (formValues.id) {
        updateMessageTemplate(
          formValues.name,
          formValues.text,
          formValues.id,
          selectedCompanyId
        ).then(() => {
          refreshMessageTemplates();
          handleClose();
        });
      } else {
        createMessageTemplate(
          formValues.name,
          formValues.text,
          selectedCompanyId
        )
          .then((response) => {
            setFormValues(response.data);
            handleClose();
            refreshMessageTemplates();
          })
          .catch(({ error }) => {
            if (error === "conflict") {
              setErrors({
                name: "Message template with this name already exists",
              });
            }
            if (error.message && error.message.includes("already exists")) {
              setErrors({
                name: "Message template with this name already exists",
              });
            }
          });
      }
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    if (
      name === "text" &&
      messageInputRef.current &&
      formValues.text.length < value.length
    ) {
      if (isShowMetadataMenu(messageInputRef.current) === "{{") {
        setOpenMetadata(true);

        if (messageInputRef.current) {
          messageInputRef.current.setSelectionRange(
            messageInputRef.current?.selectionStart,
            messageInputRef.current?.selectionStart + 1
          );
          messageInputRef.current.focus();
        }
      } else {
        setOpenMetadata(false);
      }

      if (isShowEmodjiMenu(messageInputRef.current)) {
        setOpenEmojiModal(true);
        messageInputRef.current.focus();
      } else {
        setOpenEmojiModal(false);
      }
    }

    setFormValues({ ...formValues, [name]: value });
  };

  const isValidForm = (values: typeof formValues) => {
    try {
      addMessageTemplateValidationSchema.validateSync(values, {
        abortEarly: false,
      });
      return true;
    } catch {
      return false;
    }
  };

  const validate = (values: typeof formValues) => {
    try {
      addMessageTemplateValidationSchema.validateSync(values, {
        abortEarly: false,
      });
      setErrors({});
      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const newErrors = err.inner?.reduce((acc, curr) => {
          if (curr.path) {
            acc[curr.path] = curr?.message;
          }
          return acc;
        }, {} as ValidationErrors);
        setErrors(newErrors);
      }
      return false;
    }
  };

  return (
    <Fragment>
      <BootstrapDialog

        onClose={handleClose}
        aria-labelledby="customized-dialog-title1"
        open={show}
        fullWidth={true}
        PaperProps={{ overflowY: 'visible' }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {profile?.type === "user" ? '' : formValues.id ? "Update " : "Create "}
          {"Message Template"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <ContentContainer>
            <TextField
              id="outlined-basic"
              label={"Name"}
              name="name"
              variant="outlined"
              disabled={profile?.role?.name === "company_user"}
              value={formValues.name}
              onChange={handleChange}
              error={!!errors.name}
              helperText={errors?.name || ""}
            />
          <Stack direction="column">
              {openEmojiModal && (
                <ClickAwayListener
                  onClickAway={() => {
                    setOpenEmojiModal(false);
                  }}
                >
                  <Box sx={{ position: "absolute" }}>
                    <EmojiPicker
                      autoFocusSearch={false}
                      style={{
                        zIndex: 999999,
                        position: "absolute",
                        top: "-360px",
                        left: "200px",
                      }}
                      onEmojiClick={(e: EmojiClickData) => {
                        const emoji = e.emoji;
                        const start = messageInputRef.current?.value.substring(
                          0,
                          messageInputRef.current.selectionStart
                        );
                        const end = messageInputRef.current?.value.substring(
                          messageInputRef.current.selectionStart
                        );
                        const text =
                          (start?.endsWith(":")
                            ? messageInputRef.current?.value.substring(
                                0,
                                messageInputRef.current.selectionStart - 1
                              )
                            : start) +
                          emoji +
                          end;

                        setFormValues({
                          ...formValues,
                          text: text,
                        });

                        if (messageInputRef.current) {
                          messageInputRef.current.setSelectionRange(
                            messageInputRef.current?.selectionStart,
                            messageInputRef.current?.selectionStart + 1
                          );
                        }
                        messageInputRef.current?.focus();
                        setOpenEmojiModal(false);
                      }}
                    />
                  </Box>
                </ClickAwayListener>
              )}
              <Box sx={{ position: "relative" }}>
                <Box
                  style={{
                    position: "absolute",
                    width: "100%",
                    top: "27px",
                    paddingLeft: 0,
                    outline: 0,
                    marginLeft: "10px",
                    marginRight: "10px",
                  }}
                  sx={{
                    "& .DraftEditor-root": {
                      color: "transparent",
                      lineHeight: "23px",
                      wordWrap: "false",
                      height: "100%",
                      marginLeft: "2px",
                    },
                    "& mark": {
                      color: "transparent",
                      backgroundColor: "lightgrey",
                    },
                  }}
                >
                  <HighlightWithinTextarea
                    placeholder=""
                    value={formValues.text}
                    highlight={metadata?.map((m) => "{{" + m.name + "}}")}
                  />
                </Box>
                <TextField
                  inputRef={messageInputRef}
                  fullWidth
                  disabled={profile?.role?.name === "company_user"}
                  id="outlined-multiline-static"
                  name="text"
                  value={formValues.text}
                  multiline
                  minRows={4}
                  style={{ margin: "10px 0px" }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "transparent",
                      maxHeight: "45%",
                    },
                  }}
                  onChange={handleChange}
                  InputProps={{
                    disabled: profile?.role?.name === "company_user",
                    endAdornment: (
                      <>
                        {profile?.role?.name !== "company_user" && metadata && metadata.length > 0 && (
                          <>
                            <ClickAwayListener
                              onClickAway={() => {
                                handleMetadataMenuClose();
                              }}
                            >
                              <>
                                <Button
                                  sx={{
                                    minWidth: "10px",
                                    padding: 0,
                                    width: "10px",
                                    margin: 0,
                                    position: "absolute",
                                    right: "40px",
                                    bottom: "5px",
                                  }}
                                  style={{
                                    backgroundColor: "transparent",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setOpenMetadata(true);
                                  }}
                                >
                                  <LocalOfferOutlinedIcon />
                                </Button>

                                <Menu
                                  id="long-menu"
                                  anchorEl={messageInputRef.current}
                                  MenuListProps={{
                                    "aria-labelledby": "long-button",
                                  }}
                                  open={!!openMetadata}
                                  onClose={handleMetadataMenuClose}
                                  slotProps={{
                                    paper: {
                                      style: {
                                        maxHeight: 48 * 4.5,
                                      },
                                    },
                                  }}
                                >
                                  {[
                                    ...metadata
                                      .filter((m) =>
                                        m.name.startsWith("contact.")
                                      )
                                      .sort((a, b) =>
                                        a.description
                                          .toLowerCase()
                                          .localeCompare(
                                            b.description.toLowerCase()
                                          )
                                      ),
                                    ...metadata
                                      .filter((m) =>
                                        m.name.startsWith("company.")
                                      )
                                      .sort((a, b) =>
                                        a.description
                                          .toLowerCase()
                                          .localeCompare(
                                            b.description.toLowerCase()
                                          )
                                      ),
                                  ].map((m) => (
                                    <MenuItem
                                      key={m.name}
                                      onClick={() => {
                                        insertMetadata(m.name);
                                        handleMetadataMenuClose();
                                      }}
                                    >
                                      {m.name.startsWith("contact")
                                        ? "Contact: "
                                        : "Company: "}
                                      {m.description}
                                    </MenuItem>
                                  ))}
                                </Menu>
                              </>
                            </ClickAwayListener>
                          </>
                        )}
                        {profile?.role?.name !== "company_user" && <Button
                          sx={{
                            minWidth: "10px",
                            padding: 0,
                            width: "10px",
                            margin: 0,
                            position: "absolute",
                            right: "10px",
                            bottom: "5px",
                          }}
                          style={{
                            backgroundColor: "transparent",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setOpenEmojiModal(true);
                          }}
                        >
                          <InsertEmoticonIcon />
                        </Button>}
                      </>
                    ),
                  }}
                />
              </Box>
            </Stack>
            <Stack direction="row" justifyContent="end">
              {profile?.role?.name !== "company_user" && <Button
                size="small"
                disabled={!isValidForm(formValues)}
                variant="contained"
                style={{ width: "150px", marginTop: "20px" }}
                onClick={handleMessageTemplateSave}
              >
                {formValues.id ? "Save" : "Create"}
              </Button>}
              {profile?.role?.name === "company_user" && <Button
                size="small"
                variant="contained"
                style={{ width: "150px", marginTop: "20px" }}
                onClick={handleClose}
              >
                Close
              </Button>}
            </Stack>
          </ContentContainer>
        </DialogContent>
      </BootstrapDialog>
    </Fragment>
  );
}
