import React, { Fragment, useState, useEffect, SyntheticEvent } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import { ContentContainer } from "./styles";
import Button from "@mui/material/Button";
import {
  groupValidationSchema,
  ValidationErrors,
} from "src/constants/validations";
import {
  DefaultCustomField,
  DynamicGroupRule,
  Group,
} from "src/constants/types";
import * as Yup from "yup";
import { useCreateGroupApi, useUpdateGroupApi } from "src/api/contacts";
import { useSelector } from "react-redux";
import { selectedCompany } from "src/store/company/companySlice";
import { useGetContactsApi } from "src/api/contacts";
import { useGetGroupsApi } from "src/api/contacts";
import { Contact } from "src/constants/types";
import { useAssignContactsApi, useRemoveContactsApi } from "src/api/contacts";
import SelectWithSearch from "src/components/SelectWithSearch/view";
import TextFieldWithCopyIcon from "../../../components/TextFieldWithCopyIcon/view";
import {
  FormControl,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import Rules from "./Rules/Rules";
import { useGetDefaultCustomFieldsValuesApi } from "src/api/customFields";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    width: "700px",
    height: "700px",
  },
}));

type CreateGroupModalType = {
  show: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  groupData?: Group;
  refreshGroups: () => void;
};

const initialFormValues = {
  name: "",
  description: "",
  contactIds: [],
  isDynamicGroup: false,
};
export default function CreateGroupModal({
  show,
  setShowModal,
  groupData,
  refreshGroups,
}: CreateGroupModalType) {
  const [formValues, setFormValues] = useState<{
    name: string;
    description: string;
    contactIds: string[];
    isDynamicGroup: boolean;
  }>({
    ...initialFormValues,
    isDynamicGroup: groupData?.type?.toLowerCase() === "dynamic",
  });
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [contacts, setContacts] = useState<Contact[]>();
  const [isFirstStep, setIsFirstStep] = useState(true);
  const [customFields, setCustomFields] = useState<DefaultCustomField[]>([]);
  const [loadingGroups, setLoadingGroups] = useState<boolean>(false);
  const [isUniqueName, setIsUniqueName] = useState<boolean>(false);
  const [isValidRules, setIsValidRules] = useState(true);
  const getGroups = useGetGroupsApi();
  const [rules, setRules] = useState<DynamicGroupRule[]>(
    groupData?.rules || []
  );
  const createGroup = useCreateGroupApi();
  const updateGroup = useUpdateGroupApi();
  const selectedCompanyId = useSelector(selectedCompany);
  const getContacts = useGetContactsApi();
  const [existingContactIds, setExistingContactIds] = React.useState<string[]>(
    []
  );
  const assignContacts = useAssignContactsApi();
  const removeContacts = useRemoveContactsApi();
  const getCustomFields = useGetDefaultCustomFieldsValuesApi();

  const handleSelectChange = (
    event: SyntheticEvent<Element, Event>,
    items: { text: string; value: string }[]
  ) => {
    const contactIds = items?.map(({ value }) => value) || [];
    setFormValues({ ...formValues, contactIds });
  };

  useEffect(() => {
    isGroupNameUnique();
  }, [formValues.name]);

  useEffect(() => {
    if (!isUniqueName && formValues.name) {
      setErrors({"name": "Group names must be unique."});
    }
  }, [isUniqueName])

  useEffect(() => {
    if (show && selectedCompanyId) {
      const params = {
        companyId: selectedCompanyId,
        limit: 10000,
        offset: 0,
        types: "contact,company",
      };
      getCustomFields(params)
        .then((data) => {
          setCustomFields(
            data.data.items.filter((i) => i.name.startsWith("contact."))
          );
        })
        .catch((e) => {
          console.log(e);
        });

      getContacts({
        companyId: selectedCompanyId,
        limit: 1000,
        offset: 0,
      })
        .then(({ data }) => {
          setContacts(data.items);
          groupData?.id &&
            getContacts({
              companyId: selectedCompanyId,
              limit: 1000,
              groups: [groupData.id].toString(),
              offset: 0,
            })
              .then(({ data }) => {
                const groupContacts = data?.items?.map(({ id }) => id);
                setExistingContactIds(groupContacts);
                setFormValues({
                  ...formValues,
                  ...groupData,
                  contactIds: groupContacts,
                });
                setRules(groupData.rules);
              })
              .catch((e) => {
                console.log(e);
              });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [selectedCompanyId, show, groupData?.id]);

  const handleClose = () => {
    setShowModal(false);
    setIsValidRules(true);
  };

  const isGroupNameUnique =  async () => {
    setLoadingGroups(true);
    const groups = await getGroups({
      companyId: selectedCompanyId!,
      limit: 1000,
      offset: 0,
      statuses: "active,archived",
    });

    setLoadingGroups(false);
    const isUnique = !groups.data.items.some(g => g.name === formValues.name && g.id !== groupData?.id);
    if (isUnique) {
      setErrors({});
    }
    setIsUniqueName(isUnique);
  };

  const goToNextStep = async () => {
    const isValid = await validate(formValues);
    if (isValid) {
      setIsFirstStep(false);
    }
  };

  const handleGroupSave = async () => {
    const isValid = await validate(formValues);
    if (isValid) {
      if (groupData?.id) {
        selectedCompanyId &&
          updateGroup(
            {
              name: formValues.name,
              description: formValues.description || "",
              rules: formValues.isDynamicGroup
                ? rules
                    .filter((r) => r.fieldName && r.operator)
                    .map((r) => ({
                      id: r.id,
                      fieldName: r.fieldName,
                      operator: r.operator,
                      value: r.value,
                    }))
                : undefined,
            },
            groupData?.id,
            selectedCompanyId
          ).then(() => {
            if (groupData?.id && formValues.contactIds) {
              const addedIds = formValues.contactIds.filter(
                (id) => !existingContactIds.includes(id)
              );
              const removedIds = existingContactIds.filter(
                (id) => !formValues.contactIds.includes(id)
              );
              Promise.all([
                addedIds.length &&
                  assignContacts(
                    addedIds,
                    groupData?.id,
                    selectedCompanyId as string
                  ),
                removedIds.length &&
                  removeContacts(
                    removedIds,
                    groupData?.id,
                    selectedCompanyId as string
                  ),
              ]).then(() => {
                handleClose();
                refreshGroups();
              });
            } else {
              handleClose();
              refreshGroups();
            }
          });
      } else {
        selectedCompanyId &&
          createGroup(
            {
              name: formValues.name,
              description: formValues.description || "",
              rules: formValues.isDynamicGroup
                ? rules.filter((r) => r.fieldName && r.operator)
                : undefined,
              type: formValues.isDynamicGroup ? "dynamic" : "custom",
            },
            selectedCompanyId
          ).then(({ data }) => {
            if (data.id && formValues.contactIds.length) {
              assignContacts(
                formValues.contactIds,
                data.id,
                selectedCompanyId as string
              ).then(() => {
                handleClose();
                refreshGroups();
              });
            } else {
              handleClose();
              refreshGroups();
            }
          });
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const toggleGroupType = () => {
    setFormValues({
      ...formValues,
      isDynamicGroup: !formValues.isDynamicGroup,
    });
  };

  const saveRule = (rule: DynamicGroupRule) => {
    const newRule = !rules.find((r) => r.fieldName === rule.fieldName);
    if (newRule && !rule.id) {
      setRules([...rules.filter((r) => r.fieldName), rule]);
    } else {
      const updatedRules = rules?.map((r) => {
        if (r.fieldName === rule.fieldName || r.id === rule.id) {
          return rule;
        } else {
          return r;
        }
      });
      if (updatedRules.length === 0) {
        updatedRules.push(rule);
      }
      setRules(
        updatedRules ? [...updatedRules.filter((r) => r.fieldName)] : []
      );
    }
  };

  const deleteRule = (rule: DynamicGroupRule) => {
    const filteredRules = rules?.filter((r) => r.fieldName !== rule.fieldName);
    setRules(filteredRules ? [...filteredRules] : []);
  };

  const addNewRule = () => {
    setRules([...(rules || []), { fieldName: "", operator: "", value: "" }]);
  };

  const validate = async (values: typeof formValues) => {
    try {
      await groupValidationSchema.validate(values, { abortEarly: false });
      setErrors({});
      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const newErrors = err.inner?.reduce((acc, curr) => {
          if (curr.path) {
            acc[curr.path] = curr?.message;
          }
          return acc;
        }, {} as ValidationErrors);
        setErrors(newErrors);
      }
      return false;
    }
  };

  const formatContactText = (
    firstName: string,
    lastName: string,
    phone: string
  ): string => {
    return firstName && lastName
      ? firstName + " " + lastName
      : firstName && phone
      ? firstName + " - " + `${phone}`
      : `"${phone}"`;
  };

  return (
    <Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={show}
        fullWidth={true}
        keepMounted={false}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {isFirstStep
            ? groupData
              ? "Edit Group"
              : "Create Group"
            : formValues.isDynamicGroup
            ? "Add Rules"
            : "Add Contacts"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <ContentContainer>
            {isFirstStep && (
              <TextField
                id="outlined-basic"
                label="Name"
                name="name"
                variant="outlined"
                value={formValues.name}
                onChange={handleChange}
                error={!!errors.name}
                helperText={errors?.name || ""}
              />
            )}
            {isFirstStep && groupData?.id && (
              <TextFieldWithCopyIcon
                disabled={true}
                id="outlined-multiline-static"
                label="Group ID"
                name="de456scription"
                style={{ margin: "15px 0px" }}
                value={groupData?.id}
              />
            )}
            {isFirstStep && (
              <TextField
                id="outlined-multiline-static"
                label="Description"
                name="description"
                multiline
                rows={4}
                style={{ margin: "15px 0px" }}
                value={formValues.description}
                onChange={handleChange}
              />
            )}
            {isFirstStep && (
              <FormControl
                fullWidth
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  margin: "0 0",
                  marginBottom: "10px",
                }}
              >
                <ToggleButtonGroup
                  size="small"
                  color="primary"
                  value={formValues.isDynamicGroup}
                  exclusive
                  disabled={!!groupData?.id}
                  onChange={() => {
                    toggleGroupType();
                  }}
                  aria-label="messageType"
                >
                  <ToggleButton
                    value={false}
                    selected={!formValues.isDynamicGroup}
                  >
                    Standard
                  </ToggleButton>
                  <ToggleButton
                    value={true}
                    selected={formValues.isDynamicGroup}
                  >
                    Dynamic
                  </ToggleButton>
                </ToggleButtonGroup>
              </FormControl>
            )}

            {!formValues.isDynamicGroup && !isFirstStep && (
              <SelectWithSearch
                label="Contacts"
                placeholder="Contacts"
                options={
                  contacts
                    ?.sort((a, b) =>
                      String(a.phoneNumber).localeCompare(b.phoneNumber)
                    )
                    .map(({ firstName, lastName, phoneNumber, id }) => ({
                      text: formatContactText(firstName, lastName, phoneNumber),
                      value: id,
                    })) || []
                }
                values={formValues.contactIds}
                handleSelectChange={handleSelectChange}
                style={{
                  width: "100%",
                  margin: "0 0 20px 0",
                  maxHeight: "120px",
                  paddingTop: "10px",
                  overflow: "auto",
                }}
              />
            )}
            {formValues.isDynamicGroup && !isFirstStep && (
              <Rules
                addNewRule={addNewRule}
                saveRule={saveRule}
                deleteRule={deleteRule}
                customFields={customFields}
                rules={rules}
              />
            )}
            {isFirstStep && (
              <Stack direction="row" width="100%" justifyContent="end">
                <Button
                  variant="contained"
                  style={{ width: "150px" }}
                  disabled={!formValues.name || loadingGroups || !isUniqueName}
                  onClick={goToNextStep}
                >
                  Next
                </Button>
              </Stack>
            )}
            {!isFirstStep && (
              <Stack direction="row" width="100%" justifyContent="end">
                <Button
                  variant="contained"
                  color="error"
                  style={{ width: "150px", marginRight: "10px" }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  style={{ width: "150px" }}
                  onClick={handleGroupSave}
                  disabled={!isValidRules}
                >
                  Save
                </Button>
              </Stack>
            )}
          </ContentContainer>
        </DialogContent>
      </BootstrapDialog>
    </Fragment>
  );
}
