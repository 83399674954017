import React, { useState, useEffect, SyntheticEvent } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { ContentContainer, Container, PhoneContainer } from "./styles";
import Button from "@mui/material/Button";
import {
  joinCampaignValidationSchema,
  ValidationErrors,
} from "src/constants/validations";
import { Group, Keyword, MessageTemplate } from "src/constants/types";
import * as Yup from "yup";
import {
  useCreateJoinCampaignApi,
  useUpdateJoinCampaignApi,
  useGetCampaignApi,
} from "src/api/campaigns";
import { useSelector } from "react-redux";
import { selectedCompany } from "src/store/company/companySlice";
import FormControl from "@mui/material/FormControl";
import { useGetGroupsApi, useCreateGroupApi } from "../../../api/contacts";
import { useCreateKeywordApi, useGetKeywordsApi } from "src/api/keywords";
import FormHelperText from "@mui/material/FormHelperText";
import { initialMessage, initialValues } from "./constants";
import SelectWithSearch from "src/components/SelectWithSearch/view";
import MainWrapper from "src/components/MainWrapper/view";
import { useNavigate, useParams } from "react-router-dom";
import Phone from "src/components/PhoneUI/view";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Message, MessageFormValuesType } from "./types";
import { CampaignType, ExternalType } from "src/api/campaigns/types";
import MessageForm from "./MessageForm/view";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import dayjs from "dayjs";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import useConfirmationDialog from "src/components/ConfirmationDialog/view";
import DeleteIcon from "@mui/icons-material/Delete";
import ReactRouterPrompt from "react-router-prompt";
import { DefaultCustomField } from "src/constants/types";
import { useGetDefaultCustomFieldsValuesApi } from "src/api/customFields";
import { useGetMessageTemplatesApi } from "src/api/messageTemplates";
import { GetMessageTemplatesParams } from "src/api/messageTemplates/types";
import { useHandleSelectedCompany } from "src/hooks/useHandleSelectedCompany";

const metadataLimit = 100000;
const messageTemplatesLimit = 10000;

export default function MultiJoinCampaign() {
  const initialData = {
    ...initialValues,
    scheduledMessages: initialValues.scheduledMessages.concat([initialMessage]),
  };
  const [formValues, setFormValues] =
    useState<MessageFormValuesType>(initialData);
  const [isCampaignLoaded, setIsCampaignLoaded] = useState(false);
  const [metadata, setMetadata] = useState<DefaultCustomField[]>();
  const [messageTemplates, setMessageTemplates] = useState<MessageTemplate[]>();
  const [expandedMessagePosition, setExpandedMessagePosition] = useState(0);
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [groups, setGroups] = useState<Group[]>([]);
  const getMetadata = useGetDefaultCustomFieldsValuesApi();
  const getMessageTemplates = useGetMessageTemplatesApi();
  const [keywords, setKeywords] = useState<Keyword[]>([]);
  const [isCampaingSaving, setIsCampaignSaving] = useState<boolean>(false);
  const createJoinCampaign = useCreateJoinCampaignApi();
  const updateJoinCampaign = useUpdateJoinCampaignApi();
  const getGroups = useGetGroupsApi();
  const createGroup = useCreateGroupApi();
  const getKeywords = useGetKeywordsApi();
  const createKeyword = useCreateKeywordApi();
  const getCampaign = useGetCampaignApi();
  const selectedCompanyId = useSelector(selectedCompany);
  const { campaignId } = useParams();
  const navigate = useNavigate();
  const { openDialog, ConfirmationDialog } = useConfirmationDialog();
  const [formSnapshot, setFormSnapshot] = useState<string | null>(
    campaignId ? null : JSON.stringify(initialData)
  );
  useHandleSelectedCompany();

  const handleClose = () => {
    navigate("/campaigns");
  };

  useEffect(() => {
    if (selectedCompanyId) {
      const params = {
        companyId: selectedCompanyId,
        limit: metadataLimit,
        offset: 0,
        types: 'contact,company',
      };
      getMetadata(params)
        .then((data) => {
          setMetadata(data.data.items);
        })
        .catch((e) => {
          console.log(e);
        });
      const messageTemplatesParams: GetMessageTemplatesParams = {
        companyId: selectedCompanyId,
        offset: 0,
        limit: messageTemplatesLimit,
        status: 'active',
      }
      getMessageTemplates(messageTemplatesParams).then((data) => {
        setMessageTemplates(data.data.items.filter(t => t.companyId === selectedCompanyId));
      }).catch((e) => {
        console.log(e);
      })
      getGroups({
        companyId: selectedCompanyId,
        limit: 1000,
        offset: 0,
        statuses: 'active,archived',
      })
        .then(({ data }) => {
          setGroups(data.items);
        })
        .catch((e) => {
          console.log(e);
        });
      getKeywords({
        companyId: selectedCompanyId,
        limit: 1000,
        offset: 0,
      })
        .then(({ data }) => {
          setKeywords(data.items);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [selectedCompanyId]);

  const handleCampaignSave = async () => {
    
    const isValid = await validate(formValues);
    const isCapturesInvalid = formValues.scheduledMessages.some(x => x.isCaptureContact && !x.dataCapture);

    function saveCampaign() {
      setIsCampaignSaving(true);
      const {
        name, groupIds, keywordIds, scheduledMessages, isOptOutEnabled, optOutText,
      } = formValues;
      const params = {
        campaignType: "join" as CampaignType,
        externalType: "custom" as ExternalType,
        name,
        groups: groupIds || [],
        keywords: keywordIds || [],
        scheduledMessages: scheduledMessages
          .filter((m) => m.text)
          .map((item) => {
            delete item.campaignId;
            delete item.dateSend;
            delete item.createdBy;
            delete item.updatedBy;
            delete item.dateCreated;
            delete item.dateUpdated;
            delete item.isCaptureContact;
            delete item.linksWithMimeType;
            item.links = item.type === "mms" ? item.links : [];
            item.subject = item.type === "mms" ? item.subject : "";
            return item;
          }),

        optOutText: isOptOutEnabled && optOutText ? optOutText : null,
      };
      if (campaignId) {
        selectedCompanyId &&
          updateJoinCampaign(params, campaignId, selectedCompanyId)
            .then(() => {
              handleClose();
            })
            .catch(({ error }) => {
              if (error === "conflict") {
                setErrors({ name: "Entity with this name already exists" });
              }
            });
      } else {
        selectedCompanyId &&
          createJoinCampaign(params, selectedCompanyId)
            .then(() => {
              handleClose();
            })
            .catch(({ error }) => {
              if (error === "conflict") {
                setErrors({ name: "Entity with this name already exists" });
              }
            });
      }
    }
    
    if (isValid && !isCapturesInvalid) {
      const selectedKeywords = keywords ? keywords.filter(k => formValues.keywordIds?.includes(k.id)) : [];
      if (selectedKeywords.some(i => i.campaigns.filter(c => c.status === "active").length > (campaignId ? 1 : 0))) {
        openDialog({
          title: "WARNING!",
          content:
            "Some of the keywords are already part of another active campaign, which may cause a bad experience for the recipient. Are you sure you want to continue?",
          onConfirm: () => {
            saveCampaign();
          },
          confirmButtonText: 'Continue',
        });
      } else {
        saveCampaign();
      }
    }
  };

  useEffect(() => {
    if (campaignId && selectedCompanyId) {
      getCampaign({ companyId: selectedCompanyId }, campaignId).then(
        ({ data }) => {
          setIsCampaignLoaded(true);
          const {
            name,
            groups,
            keywords,
            dateScheduled,
            scheduledMessages,
            optOutText,
          } = data;
          const formData = {
            name,
            groupIds: groups.map(({ id }) => id),
            keywordIds: keywords.map(({ id }) => id),
            dateScheduled,
            scheduledMessages:
              scheduledMessages.length > 1
                ? scheduledMessages.sort((a, b) => a.position - b.position)
                : scheduledMessages,
            isOptOutEnabled: !!optOutText,
            optOutText: optOutText || "",
          };
          setFormValues(formData);
          setFormSnapshot(JSON.stringify(formData));
        }
      );
    } else {
      setFormSnapshot(JSON.stringify(formValues));
      setIsCampaignLoaded(true);
    }
  }, [campaignId, selectedCompanyId]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
      dateScheduled: formValues.dateScheduled ?? null,
    });
  };

  const handleSelectChange = (
    _: SyntheticEvent<Element, Event>,
    items: { text: string; value: string }[],
    name: string
  ) => {
    const ids = items?.map(({ value }) => value) || [];
    setFormValues({ ...formValues, [name]: ids });
  };

  const updateMessage = (message: Message) => {
    const scheduledMessages = [...formValues.scheduledMessages];
    const messageIndex = scheduledMessages.findIndex(
      (m) => m.position === message.position
    );
    scheduledMessages[messageIndex] = message;
    setFormValues({ ...formValues, scheduledMessages: scheduledMessages });
  };

  const addMessage = (position: number, previousMessage: Message | undefined = undefined): void => {
    const message = formValues.scheduledMessages.find(
      (m) => m.position === position
    );
    if (!message) {
      return;
    }
    let rearrangedMessages = formValues.scheduledMessages.filter(
      (m) => m.position <= position
    );

    const newMessage: Message = {
      ...initialMessage,
      position: message.position + 1,
      scheduledType: previousMessage && (previousMessage.scheduledType === 'reply' || previousMessage?.dataCapture) ? 'reply' : 'immediate'
    };
    rearrangedMessages = rearrangedMessages
      .concat([newMessage])
      .concat(
        formValues.scheduledMessages
          .filter((m) => m.position > position)
          .map((i) => ({ ...i, position: i.position + 1 }))
      );
    setFormValues({
      ...formValues,
      scheduledMessages: rearrangedMessages,
    });
    setExpandedMessagePosition(newMessage.position);
  };

  const deleteMessage = (position: number): void => {
    const message = formValues.scheduledMessages.find(
      (m) => m.position === position
    );
    if (!message) {
      return;
    }
    let rearrangedMessages = formValues.scheduledMessages.filter(
      (m) => m.position < position
    );
    rearrangedMessages = rearrangedMessages.concat(
      formValues.scheduledMessages
        .filter((m) => m.position > position)
        .map((i) => ({ ...i, position: i.position - 1 }))
    );
    setFormValues({
      ...formValues,
      scheduledMessages: rearrangedMessages,
    });
  };

  const isDirty = (message: Message): boolean => {
    if (message.isCaptureContact && !message.dataCapture) {
      return false;
    }
    return !!message.text || message.links.length > 0;
  };

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.checked,
      ...(event.target.checked ? { optOutText: "Reply STOP to opt-out" } : {}),
    });
  };

  if (!isCampaignLoaded) {
    return null;
  }

  const message =
    formValues.scheduledMessages && formValues.scheduledMessages.length > 0
      ? formValues.scheduledMessages[0]
      : { ...initialMessage };
  const isBtnsDisabled = formValues.scheduledMessages.some(x => x.isCaptureContact && !x.dataCapture) ||
    !formValues.name || !message?.text || !formValues.groupIds?.length;

  const previewMessages = formValues.scheduledMessages.map((m) => ({
    isUser: false,
    text: m.text,
    links: m.links,
    linksWithMimeType: m.linksWithMimeType,
    subject: m.subject,
    position: m.position,
  }));

  const handleAddKeyword = (keyword: string): void => {
    createKeyword({ name: keyword }, selectedCompanyId!)
      .then(() => {
        getKeywords({
          companyId: selectedCompanyId!,
          limit: 1000,
          offset: 0,
        })
          .then(({ data }) => {
            const newKeyword = data.items.find((k) => k.name === keyword);
            if (newKeyword) {
              const selectedKeywords = [...formValues.keywordIds!];
              selectedKeywords.push(newKeyword.id);
              setFormValues({ ...formValues, keywordIds: selectedKeywords });
              setKeywords(data.items);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleAddGroup = (group: string): void => {
    createGroup(
      { name: group, description: "", type: "custom" },
      selectedCompanyId!
    )
      .then(() => {
        getGroups({
          companyId: selectedCompanyId!,
          limit: 1000,
          offset: 0,
        })
          .then(({ data }) => {
            const newGroup = data.items.find((k) => k.name === group);
            if (newGroup) {
              const selectedGroups = [...formValues.groupIds!];
              selectedGroups.push(newGroup.id);
              setFormValues({ ...formValues, groupIds: selectedGroups });
              setGroups(data.items);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const isKeywordUsedInDifferentCampaigns = (keywordId: string) => {
    const campaigns = keywords.filter(k => k.id === keywordId).flatMap(k => k.campaigns).filter(c => c.id !== campaignId && c.status === "active");
    return campaigns.length > 0;
  }

  const validate = async (values: typeof formValues) => {
    try {
      await joinCampaignValidationSchema.validate(values, {
        abortEarly: false,
      });
      setErrors({});
      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const newErrors = err.inner?.reduce((acc, curr) => {
          if (curr.path) {
            acc[curr.path] = curr?.message;
          }
          return acc;
        }, {} as ValidationErrors);
        setErrors(newErrors);
      }
      return false;
    }
  };

  return (
    <MainWrapper>
      <ReactRouterPrompt
        when={!isCampaingSaving && formSnapshot !== JSON.stringify(formValues)}
      >
        {({ isActive, onConfirm, onCancel }) =>
          isActive && (
            <Dialog open={true}>
              <DialogTitle>You have unsaved changes!</DialogTitle>
              <DialogContent>
                Do you want to continue without saving?
              </DialogContent>
              <DialogActions>
                <Button onClick={onCancel} color="primary">
                  No
                </Button>
                <Button onClick={onConfirm} color="secondary">
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          )
        }
      </ReactRouterPrompt>
      <DialogTitle
        sx={{ m: 0, p: 2 }}
        id="customized-dialog-title"
        style={{ padding: 0 }}
      >
        {campaignId ? "Update " : "Create "} Custom Text-To-Join Campaign
      </DialogTitle>
      <Container>
        <ContentContainer>
          <TextField
            id="outlined-basic"
            label="Campaign Name"
            name="name"
            variant="outlined"
            value={formValues.name}
            onChange={handleChange}
            error={!!errors.name}
            helperText={errors?.name || ""}
          />
          <FormControl
            sx={{ m: 1, width: "100%", margin: "20px 0 20px 0" }}
            error={!!errors.keywordIds}
          >
            <SelectWithSearch
              label="Keyword(s)"
              placeholder="Keyword(s)"
              options={
                keywords
                  ?.filter((item) => item.type !== "default")
                  .map(({ name, id }) => ({
                    text: name,
                    value: id,
                   hasWarning: isKeywordUsedInDifferentCampaigns(id),
                  })) || []
              }
              values={formValues.keywordIds || []}
              handleSelectChange={(e, items) => {
                const lastAddedGroup = items[items.length - 1];
                if (lastAddedGroup?.hasWarning && items.length > (formValues.keywordIds?.length || 0)) {
                  openDialog({
                    title: "WARNING!",
                    content:
                      "This keyword is already part of another active campaign, which may cause a bad experience for the recipient. Are you sure you want to continue?",
                    onConfirm: () => {
                      handleSelectChange(e, items, "keywordIds");
                    },
                    confirmButtonText: 'Continue',
                  });
                } else {
                  handleSelectChange(e, items, "keywordIds");
                }
              }}
              handleAddItem={handleAddKeyword}
              style={{ width: "100%", maxHeight: "120px" }}
            />
            <FormHelperText>{errors?.keywordIds || ""}</FormHelperText>
          </FormControl>
          <FormControl
            sx={{ m: 1, width: "100%", margin: "0 0 20px 0" }}
            error={!!errors.groupIds}
          >
            <SelectWithSearch
              label="Group(s) to Join"
              placeholder="Group(s) to Join"
              options={[
                ...(groups?.filter(g => g.type === 'default').map(({ name, id, status, type }) => ({ text: name, value: id, isArchived: status === 'archived', isDisabled: type === "dynamic"  })) || []),
                ...(groups?.filter(g => g.type !== 'default').sort(((a, b) => a.type.toLowerCase().localeCompare(b.type.toLowerCase()))).map(({ name, id, status, type }) => ({ text: name, value: id, isArchived: status === 'archived', isDisabled: type === "dynamic"  })) || [])
              ]
              }
              values={formValues.groupIds || []}
              handleSelectChange={(e, items) =>
                handleSelectChange(e, items, "groupIds")
              }
              handleAddItem={handleAddGroup}
              style={{ width: "100%", maxHeight: "120px" }}
            />
            <FormHelperText>{errors?.groupIds || ""}</FormHelperText>
          </FormControl>
          {formValues.scheduledMessages.map((m, i) => (
            <div key={i} style={{ textAlign: "center" }}>
              <Accordion
                onChange={(e, expanded) => {
                  e.currentTarget.scrollIntoView({ behavior: "smooth" });
                  setExpandedMessagePosition(expanded ? m.position : 0);
                }}
                expanded={
                  m.position === expandedMessagePosition ||
                  formValues.scheduledMessages.length === 1
                }
              >
                <AccordionSummary
                  style={{ overflow: "hidden" }}
                  sx={{
                    ".MuiAccordionSummary-content": {
                      overflow: "hidden",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: "0 !important",
                      marginTop: "0 !important",
                    },
                  }}
                  expandIcon={
                    formValues.scheduledMessages.length > 1 ? (
                      <ArrowDropDownIcon />
                    ) : null
                  }
                  id="panel-header"
                  aria-controls="panel-content"
                >
                  <Box
                    style={{
                      overflow: "hidden",
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    display={"flex"}
                    flexDirection={"column"}
                  >
                    <div
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textAlign: "left",
                        textWrap: "nowrap",
                        marginRight: "5px",
                        width: "90%",
                      }}
                    >
                      {m?.text ? (
                        m?.text
                      ) : (
                        <span style={{ color: "#808080" }}>Edit Message</span>
                      )}
                    </div>
                    <div
                      style={{
                        marginTop: "5px",
                        color: "#b2b2b2",
                        fontSize: "13px",
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <div style={{ height: "16px" }}>
                      {m.scheduledType === "reply" && (
                          <>
                            Scheduled: After Contact Response to the Previous Message
                          </>
                        )}
                        {m.scheduledType === "immediate" && (
                          <>
                            Scheduled: Immediately
                            {i > 0 ? " after the previous message" : ""}
                          </>
                        )}
                        {m.scheduledType === "delay" &&
                          m.delay &&
                          m.delay.length > 1 && (
                            <>Scheduled: {m.delay} after previous message</>
                          )}
                        {m.scheduledType === "time" && m.time && (
                          <>
                            Scheduled:{" "}
                            {dayjs(m.time).format("YYYY-MM-DD hh:mm A")}
                          </>
                        )}
                      </div>
                      {m.position > 1 && (
                        <IconButton
                          sx={{
                            "&:hover": {
                              borderRadius: "4px",
                              backgroundColor: "#2545970a",
                            },
                          }}
                          size="small"
                          color="primary"
                          style={{
                            marginTop: "-25px",
                            borderRadius: "4px",
                            width: "40px",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            openDialog({
                              title: "Delete",
                              content:
                                "Are you sure you want to delete this message?",
                              onConfirm: () => {
                                deleteMessage(m.position);
                              },
                            });
                          }}
                        >
                          <DeleteIcon style={{ transform: "scale(0.8)" }} />
                        </IconButton>
                      )}
                    </div>
                  </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ textAlign: "left", paddingTop: "0" }}>
                  <MessageForm
                    metadata={metadata}
                    messageTemplates={messageTemplates}
                    message={m}
                    updateMessage={updateMessage}
                  />
                </AccordionDetails>
              </Accordion>
              <IconButton
                sx={{
                  "&:hover": {
                    borderRadius: "4px",
                    backgroundColor: "#2545970a",
                  },
                }}
                color="primary"
                style={{ borderRadius: "4px" }}
                disabled={!isDirty(m)}
                onClick={() => addMessage(m.position, m)}
              >
                <AddCircleOutlineIcon style={{ transform: "scale(0.8)" }} />
              </IconButton>
            </div>
          ))}
          <FormControlLabel
            control={
              <Switch
                checked={formValues.isOptOutEnabled}
                onChange={handleToggleChange}
                name="isOptOutEnabled"
              />
            }
            label="Include opt-out message"
            labelPlacement="start"
            style={{ marginLeft: 0, justifyContent: "flex-end" }}
          />
          {formValues.isOptOutEnabled && (
            <TextField
              id="outlined-basic"
              label="Opt-out message"
              name="optOutText"
              variant="outlined"
              style={{ margin: "20px 0px" }}
              value={formValues.optOutText}
              onChange={handleChange}
            />
          )}

          <Button
            disabled={isBtnsDisabled}
            variant="contained"
            style={{ width: "150px", marginTop: "20px" }}
            onClick={handleCampaignSave}
          >
            {campaignId ? "Update " : "Create "}
          </Button>
        </ContentContainer>
        <PhoneContainer>
          <Phone
            optOutText={formValues.isOptOutEnabled ? formValues.optOutText : ""}
            messages={[
              {
                isUser: true,
                text: formValues.keywordIds?.length
                  ? keywords.find(
                      (item) => item.id === formValues.keywordIds?.[0]
                    )?.name || ""
                  : "",
              },
            ].concat(previewMessages.sort((a, b) => a.position - b.position))}
          />
        </PhoneContainer>
      </Container>
      <ConfirmationDialog />
    </MainWrapper>
  );
}
