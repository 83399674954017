import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectedCompany, setSelectedCompany } from "src/store/company/companySlice";

export const useHandleSelectedCompany = () => {
  const dispatch = useDispatch();
  const selectedCompanyId = useSelector(selectedCompany);

  useEffect(() => {
    const storedCompany = localStorage.getItem('bhiCurrentCompany');
    if (storedCompany && storedCompany !== selectedCompanyId) {
      dispatch(setSelectedCompany(storedCompany));
    }
  }, []);
}