import React, { Fragment, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import { ContentContainer } from './styles';
import Button from '@mui/material/Button';
import { keywordValidationSchema, ValidationErrors } from 'src/constants/validations';
import { Keyword } from 'src/constants/types';
import * as Yup from 'yup';
import { useCreateKeywordApi, useUpdateKeywordApi } from 'src/api/keywords';
import { useSelector } from 'react-redux';
import { selectedCompany } from 'src/store/company/companySlice';
import { Stack } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
        width: '700px',
        height: '700px'
    },
}));

type CreateKeywordModalType = {
    show: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    keywordData?: Keyword;
    refreshKeywords: () => void;
}

export default function CreateKeywordModal({show, setShowModal, keywordData, refreshKeywords}: CreateKeywordModalType) {
    const [formValues, setFormValues] = useState({ name: ''});
    const [errors, setErrors] = useState<ValidationErrors>({});
    const createKeyword = useCreateKeywordApi();
    const updateKeyword = useUpdateKeywordApi();
    const selectedCompanyId = useSelector(selectedCompany);

    const handleClose = () => {
        setFormValues({ name: ''});
        setShowModal(false);
    }

    const handleKeywordSave = async () => {
        const isValid = await validate(formValues);
        if (isValid) {
            if (keywordData?.id) {
                selectedCompanyId && updateKeyword({name: formValues.name}, keywordData?.id, selectedCompanyId).then(() => {
                    handleClose()
                    refreshKeywords();
                })
            } else {
                selectedCompanyId && createKeyword(formValues, selectedCompanyId).then(() => {
                    handleClose()
                    refreshKeywords();
                }).catch(({error}) => {
                    if (error === 'conflict') {
                        setErrors({name: 'Entity with this name already exists'})
                    }
                })
            }
        }
    }

    useEffect(() => {
        keywordData && setFormValues(keywordData);
    }, [keywordData])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const validate = async(values: typeof formValues) => {
        try {
            await keywordValidationSchema.validate(values, { abortEarly: false });
            setErrors({});
            return true;
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const newErrors = err.inner?.reduce((acc, curr) => {
                    if (curr.path) {
                        acc[curr.path] = curr?.message;
                    }
                    return acc;
                }, {} as ValidationErrors);
                setErrors(newErrors);
            }
            return false;
        }
    };

    return (
        <Fragment>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={show}
                fullWidth={true}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" >
                    {keywordData ? 'Update ' : 'Create '} Keyword
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <ContentContainer>
                        <TextField id="outlined-basic" label="Name" name="name" variant="outlined" value={formValues.name} onChange={handleChange} error={!!errors.name} helperText={errors?.name || ''} />
                        <Stack direction="row" width="100%" justifyContent="end">
                            <Button variant="contained" style={{ width: '150px', marginTop: '20px' }} onClick={handleKeywordSave}>Save</Button>
                        </Stack>  
                    </ContentContainer>
                </DialogContent>
            </BootstrapDialog>
        </Fragment>
    );
}
