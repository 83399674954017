import { GetGroupsApiParams, CreateGroupApiParams, ArchiveUnarchiveGroupApiParams, TestGroupRulesApiParams, GetGroupApiParams, AssignContactsApiParams, DeleteContactApiParams, RemoveContactsApiParams, UpdateGroupApiParams, GetContactsApiParams, CreateContactsApiParams, UpdateContactApiParams, AddBulkContactsApiParams } from './types';
import { useCallback } from 'react';
import apiClient from '../axiosClient';

/**
 * @function useGetGroupsApi
 * @returns { GetGroupsApiParams }
 */
export function useGetGroupsApi(): GetGroupsApiParams {
  return useCallback(params => apiClient.get(`/groups`, {params}), []);
}

/**
 * @function useCreateGroupApi
 * @returns { CreateGroupApiParams }
 */
export function useCreateGroupApi(): CreateGroupApiParams {
  return useCallback((params, companyId) => apiClient.post(`/groups`, params, {params: {companyId}}), []);
}

export function useTestGroupRulesApi(): TestGroupRulesApiParams {
  return useCallback((params, companyId) => apiClient.post(`/groups/test-rules`, params, {params: {companyId}}), []);
}

/**
 * @function useUpdateGroupApi
 * @returns { UpdateGroupApiParams }
 */
export function useUpdateGroupApi(): UpdateGroupApiParams {
  return useCallback((params, groupId, companyId) => apiClient.put(`/groups/${groupId}`, params, {params: {companyId}}), []);
}

/**
 * @function useDeleteGroupApi
 * @returns { DeleteGroupApiParams }
 */
export function useArchiveUnarchiveGroupApi(): ArchiveUnarchiveGroupApiParams {
  return useCallback((groupId, name, description, companyId, status) => apiClient.patch(`/groups/${groupId}`, { name, description, status }, {params: {companyId}}), []);
}

/**
 * @function useGroupContactsApi
 * @returns { GetContactsApiParams }
 */
export function useGetContactsApi(): GetContactsApiParams {
  return useCallback(params => apiClient.get(`/contacts`, {params}), []);
}


/**
 * @function useGetGroupApi
 * @returns { GetGroupApiParams }
 */
export function useGetGroupApi(): GetGroupApiParams {
  return useCallback((groupId, params) => apiClient.get(`/groups/${groupId}`, {params}), []);
}

/**
 * @function useCreateContactsApi
 * @returns { CreateContactsApiParams }
 */
export function useCreateContactsApi(): CreateContactsApiParams {
  return useCallback((params, companyId) =>  apiClient.post(`/contacts`, params, {params: {companyId}}), []);
}

/**
 * @function useUpdateContactApi
 * @returns { UpdateContactApiParams }
 */
export function useUpdateContactApi(): UpdateContactApiParams {
  return useCallback((params, contactId, companyId) => apiClient.put(`/contacts/${contactId}`, params, {params: {companyId}}), []);
}

/**
 * @function useAssignContactsApi
 * @returns { AssignContactsApiParams }
 */
export function useAssignContactsApi(): AssignContactsApiParams {
  return useCallback((params, groupId, companyId) => apiClient.post(`/groups/${groupId}/add-contacts`, params, {params: {companyId}}), []);
}

/**
 * @function useRemoveContactsApi
 * @returns { RemoveContactsApiParams }
 */
export function useRemoveContactsApi(): RemoveContactsApiParams {
  return useCallback((params, groupId, companyId) => apiClient.delete(`/groups/${groupId}/remove-contacts`,  {params: {companyId}, data: params}), []);
}

/**
 * @function useDeleteContactApi
 * @returns { DeleteContactApiParams }
 */
export function useDeleteContactApi(): DeleteContactApiParams {
  return useCallback((contactId, companyId) => apiClient.delete(`/contacts/${contactId}`, {params: {companyId}}), []);
}

/**
 * @function useAddBulkContactsApi
 * @returns { AddBulkContactsApiParams }
 */
export function useAddBulkContactsApi(): AddBulkContactsApiParams {
  return useCallback((params, companyId) => apiClient.post(`/contacts/csv-upload`, params, {params: {companyId}}), []);
}