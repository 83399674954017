import { Column } from "./types";

export const columns: Column[] = [
    { id: 'name', label: 'Keyword', minWidth: 100 },
    { id: 'type', label: 'Type', minWidth: 70 },
    { id: 'campaigns', label: 'Campaigns', minWidth: 150 },
    {
      id: 'createdAt',
      label: 'Created',
      minWidth: 100,
    },
    {
      id: 'actions',
      label: 'Actions',
      minWidth: 100,
      align: 'center',
    },
  ];

export const itemsPerPage = 25;

 