import React, { Fragment, useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import {
  ContentContainer,
  Container,
  TableContainer,
  FooterContainer,
  StyledTextField,
} from "./styles";
import Button from "@mui/material/Button";
import { Contact, ContactCustomField, CustomField } from "src/constants/types";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import moment from "moment-timezone";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  createContactValidationSchema,
  ValidationErrors,
} from "src/constants/validations";
import { Group } from "src/constants/types";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { selectedCompany } from "src/store/company/companySlice";
import { useGetGroupsApi } from "src/api/contacts";
import {
  useAssignContactsApi,
  useCreateContactsApi,
  useUpdateContactApi,
} from "../../../api/contacts";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputAdornment from "@mui/material/InputAdornment";
import {
  useAddContactCustomField,
  useDeleteContactCustomField,
} from "src/api/customFields";
import { Stack } from "@mui/material";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    height: "700px",
  },
  "& .MuiPaper-root": {
    maxWidth: "unset",
  },
}));

type CreateContactModalType = {
  show: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  contactData?: Contact;
  groupData?: Group;
  refresh: () => void;
  customFields?: CustomField[];
};

const timeZones = moment.tz.zonesForCountry("US");
const currentTimezone = timeZones.includes(moment.tz.guess())
  ? moment.tz.guess()
  : timeZones[0];
const initialValues = {
  email: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  timeZone: currentTimezone,
  birthdate: "",
  metadata: [] as ContactCustomField[],
};

export default function CreateContactModal({
  show,
  setShowModal,
  groupData,
  refresh,
  contactData,
  customFields,
}: CreateContactModalType) {
  const [formValues, setFormValues] = useState(initialValues);
  const [selectedGroupId, setSelectedGroup] = useState<string | undefined>(
    groupData?.id
  );
  const [pendingContacts, setPendingContacts] = useState<(typeof formValues)[]>(
    []
  );
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [groups, setGroups] = useState<Group[]>();
  const tableRef = useRef<HTMLTableElement>(null);
  const [isAccepting, setIsAccepting] = useState(false);
  const getGroups = useGetGroupsApi();
  const createContacts = useCreateContactsApi();
  const assignContacts = useAssignContactsApi();
  const selectedCompanyId: string | undefined = useSelector(selectedCompany);
  const updateContact = useUpdateContactApi();
  const addContactCustomField = useAddContactCustomField();
  const deleteContactCustomField = useDeleteContactCustomField();
  const handleClose = () => {
    setFormValues(initialValues);
    setErrors({});
    setShowModal(false);
    refresh();
  };

  useEffect(() => {
    selectedCompanyId &&
      getGroups({
        companyId: selectedCompanyId,
        limit: 1000,
        offset: 0,
      })
        .then(({ data }) => {
          setGroups(data.items?.filter(g => g.type === 'custom'));
        })
        .catch((e) => {
          console.log(e);
        });
  }, [selectedCompanyId]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleMetadataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const newFormValues = { ...formValues };
    newFormValues.metadata = newFormValues.metadata.map((m) => {
      if (m.metadataId === name) {
        m.value = value;
        return m;
      } else {
        return m;
      }
    });
    setFormValues(newFormValues);
  };

  const handleDateChange = (value: dayjs.Dayjs | null) => {
    setFormValues({
      ...formValues,
      birthdate: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  const handleSelectChange = ({ target }: SelectChangeEvent<string>) => {
    setFormValues({ ...formValues, timeZone: target.value });
  };

  useEffect(() => {
    groupData?.id && setSelectedGroup(groupData?.id);
  }, [groupData?.id]);

  const validate = async (values: typeof formValues) => {
    try {
      await createContactValidationSchema.validate(values, {
        abortEarly: false,
      });
      setErrors({});
      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const newErrors = err.inner?.reduce((acc, curr) => {
          if (curr.path) {
            acc[curr.path] = curr?.message;
          }
          return acc;
        }, {} as ValidationErrors);
        setErrors(newErrors);
      }
      return false;
    }
  };

  const handleAddContact = async () => {
    const isValid = await validate(formValues);
    if (isValid) {
      setPendingContacts([...pendingContacts, formValues]);
      setFormValues(initialValues);
      if (customFields) {
        if (customFields && customFields.length > 0) {
          setFormValues({
            ...initialValues,
            metadata: customFields.map(
              (customField) =>
                ({
                  contactId: "",
                  metadataId: customField.id,
                  type: customField.type,
                  name: customField.name,
                  description: customField.description,
                  value: "",
                } as ContactCustomField)
            ),
          });
        }
      }
    }
  };

  const handleContactRemove = (index: number) => {
    const newItems = [
      ...pendingContacts.slice(0, index),
      ...pendingContacts.slice(index + 1),
    ];
    setPendingContacts(newItems);
  };

  const handleSaveContacts = () => {
    if (pendingContacts.length) {
      const updatedPendingContacts = pendingContacts.map((item) => {
        return { ...item, phoneNumber: "+1" + item.phoneNumber };
      });

      const metadata =
        updatedPendingContacts && updatedPendingContacts.length > 0
          ? updatedPendingContacts[0].metadata
          : undefined;

      if (selectedCompanyId) {
        const contacts = updatedPendingContacts.map((item) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { metadata, ...data } = item;
          data.birthdate = data.birthdate ? moment(data.birthdate).utc().format('YYYY-MM-DD') : "";
          return { ...data };
        });

        createContacts(contacts, selectedCompanyId)
          .then(({ data }) => {
            metadata &&
              data.map((contactData) => {
                metadata.map((m) => {
                  if (m.value) {
                    addContactCustomField(
                      [
                        {
                          metadataId: m.metadataId,
                          value: m.value.toString(),
                        },
                      ],
                      contactData.id
                    );
                  }
                  if (!m.value && m.id) {
                    deleteContactCustomField(m.metadataId, contactData.id);
                  }
                });
              });

            if (selectedGroupId) {
              const group = groups?.find((item) => item.id === selectedGroupId);
              if (group?.type !== "default") {
                const ids = data.map(({ id }) => id);
                assignContacts(ids, selectedGroupId, selectedCompanyId).then(() => {
                  handleClose();
                });
              }
            } else {
              handleClose();
            }
          })
          .catch((e) => {
            if (e.error === "conflict" || e.message.includes("duplicate")) {
              setErrors({
                duplicate:
                  "You have duplicated contacts, please check and then submit.",
              });
            } else if (e.error === "validation") {
              setErrors({
                validation:
                  "Provided contact are not valid, please check and then submit.",
              });
            }
          });
      }
    } else {
      handleClose();
    }
  };

  const handleUpdateContact = () => {
    if (selectedCompanyId && contactData?.id) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { metadata, ...data } = formValues;
      data.birthdate = data.birthdate ? moment(data.birthdate).utc().format('YYYY-MM-DD') : "";
      updateContact(data, contactData?.id, selectedCompanyId)
        .then(() => {
          formValues.metadata.map((m) => {
            if (m.value) {
              addContactCustomField(
                [
                  {
                    metadataId: m.metadataId,
                    value: m.value.toString(),
                  },
                ],
                contactData.id
              );
            }
            if (!m.value && m.id) {
              deleteContactCustomField(m.metadataId, contactData.id);
            }
          });
          handleClose();
          refresh();
        })
        .catch((e) => {
          if (e.error === "conflict" || e.message.includes("duplicate")) {
            setErrors({
              duplicate:
                "You have duplicated contacts, please check and then submit.",
            });
          } else if (e.error === "validation") {
            setErrors({
              validation:
                "Provided contact are not valid, please check and then submit.",
            });
          }
        });
    }
  };

  useEffect(() => {
    if (contactData) {
      setFormValues({
        firstName: contactData.firstName,
        lastName: contactData.lastName,
        email: contactData.email,
        birthdate: contactData?.birthdate,
        timeZone: contactData.timeZone,
        phoneNumber: contactData.phoneNumber,
        metadata: contactData.metadata,
      });
    } else {
      if (customFields) {
        if (customFields && customFields.length > 0) {
          setFormValues({
            ...initialValues,
            metadata: customFields.map(
              (customField) =>
                ({
                  contactId: "",
                  metadataId: customField.id,
                  type: customField.type,
                  name: customField.name,
                  description: customField.description,
                  value: "",
                } as ContactCustomField)
            ),
          });
        }
      }
    }
  }, [contactData?.id]);

  return (
    <Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={show}
        keepMounted={false}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {contactData ? "Edit Contact" : "Add Contacts"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", alignItems: "end" }}
        >
          <Container>
            <ContentContainer hideBorder={!!contactData}>
              <Stack
                sx={{
                  maxHeight: "540px",
                  overflowY: "auto",
                  paddingTop: "5px",
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="First Name"
                  name="firstName"
                  variant="outlined"
                  value={formValues.firstName}
                  onChange={handleChange}
                />
                <TextField
                  id="outlined-basic"
                  style={{ marginTop: "20px" }}
                  label="Last Name"
                  name="lastName"
                  variant="outlined"
                  value={formValues.lastName}
                  onChange={handleChange}
                />
                <TextField
                  id="outlined-basic"
                  style={{ marginTop: "20px" }}
                  label="Email"
                  name="email"
                  variant="outlined"
                  value={formValues.email}
                  onChange={handleChange}
                  error={!!errors?.email}
                  helperText={errors?.email || ""}
                />
                <StyledTextField
                  id="outlined-basic"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+1</InputAdornment>
                    ),
                  }}
                  label="Phone Number"
                  name="phoneNumber"
                  type="number"
                  variant="outlined"
                  value={formValues.phoneNumber.replace("+1", "")}
                  onChange={handleChange}
                  error={!!errors?.phoneNumber}
                  helperText={errors?.phoneNumber || ""}
                />
                <FormControl>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={["DatePicker"]}
                      sx={{ marginTop: "12px" }}
                    >
                      <DatePicker
                        label="Birthdate"
                        onChange={handleDateChange}
                        value={
                          formValues.birthdate
                            ? dayjs.utc(formValues.birthdate)
                            : null
                        }
                        defaultValue={
                          formValues.birthdate
                            ? dayjs.utc(formValues.birthdate)
                            : null
                        }
                        sx={{ width: "100%" }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </FormControl>

                <FormControl sx={{ marginTop: "20px" }}>
                  <InputLabel htmlFor="bhi-simple-select">Timezone</InputLabel>
                  <Select
                    labelId="bhi-simple-select-label"
                    id="bhi-simple-select"
                    value={formValues.timeZone}
                    label="Timezone"
                    onChange={handleSelectChange}
                    sx={{ minWidth: "150px" }}
                  >
                    {timeZones.map((item) => {
                      return (
                        <MenuItem value={item} key={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                {formValues.metadata.sort((a, b) => a.description.toLowerCase().localeCompare(b.description.toLowerCase())).map((m) => (
                  <TextField
                    key={m.metadataId}
                    type={m.type}
                    style={{ marginTop: "20px" }}
                    id="outlined-basic"
                    label={m.description}
                    name={m.metadataId}
                    variant="outlined"
                    value={m.value}
                    onChange={handleMetadataChange}
                  />
                ))}
              </Stack>
              {!contactData && (
                <Button
                  variant="contained"
                  style={{ width: "100px", marginTop: "20px" }}
                  onClick={handleAddContact}
                >
                  Add
                </Button>
              )}
            </ContentContainer>
            {!contactData && (
              <TableContainer>
                <FormControl>
                  <InputLabel htmlFor="bhi-simple-select-group">
                    Group
                  </InputLabel>
                  <Select
                    labelId="bhi-simple-select-label"
                    id="bhi-simple-select-group"
                    value={selectedGroupId}
                    defaultValue={selectedGroupId}
                    label={"Group"}
                    onChange={({ target }) =>
                      target.value && setSelectedGroup(target.value)
                    }
                    sx={{ minWidth: "150px" }}
                  >
                    {groups?.map((item) => {
                      return (
                        <MenuItem
                          value={item.id}
                          key={item.id}
                          disabled={item.type === "default"}
                        >
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <Table ref={tableRef}  aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Phone Number</TableCell>
                      <TableCell>Birthdate</TableCell>
                      <TableCell>Timezone</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pendingContacts.map(
                      (
                        {
                          firstName,
                          lastName,
                          email,
                          phoneNumber,
                          birthdate,
                          timeZone,
                        },
                        index
                      ) => (
                        <TableRow key={phoneNumber}>
                          <TableCell component="th" scope="row">
                            {firstName || lastName
                              ? firstName + " " + lastName
                              : "-"}
                          </TableCell>
                          <TableCell>{email || "-"}</TableCell>
                          <TableCell>{phoneNumber}</TableCell>
                          <TableCell>{birthdate || "-"}</TableCell>
                          <TableCell>{timeZone || "-"}</TableCell>
                          <TableCell>
                            <Button onClick={() => handleContactRemove(index)}>
                              <DeleteIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Container>
          {errors?.duplicate && (
            <FormHelperText error>{errors?.duplicate}</FormHelperText>
          )}
          {errors?.validation && (
            <FormHelperText error>{errors?.validation}</FormHelperText>
          )}
          <FooterContainer>
            {!contactData && (
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(event) => setIsAccepting(event.target.checked)}
                  />
                }
                label="I confirm that I have received express consent to message the contacts that I am adding."
              />
            )}
            {contactData ? (
              <Button
                variant="contained"
                style={{ width: "180px" }}
                onClick={handleUpdateContact}
              >
                Update Contact
              </Button>
            ) : (
              <Button
                variant="contained"
                style={{ width: "180px" }}
                onClick={handleSaveContacts}
                disabled={!isAccepting}
              >
                Save Contacts
              </Button>
            )}
          </FooterContainer>
        </DialogContent>
      </BootstrapDialog>
    </Fragment>
  );
}
