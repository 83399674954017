import React, { useState, useEffect, useRef } from "react";
import MainWrapper from "src/components/MainWrapper/view";
import { PageHeader, HeaderContainer } from "./styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Button from "@mui/material/Button";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useSelector } from "react-redux";
import { selectedCompany } from "src/store/company/companySlice";
import CreateGroupModal from "../Groups/CreateGroupModal/view";
import { formatDateWithoutTimezone } from "src/constants/helpers";
import { customFieldsLimit, itemsPerPage } from "./costants";
import { ContactCustomField, CustomField, Group } from "src/constants/types";
import { Contact } from "src/constants/types";
import { useDeleteContactApi, useGetContactsApi } from "src/api/contacts";
import { useParams, Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CreateContactModal from "../Groups/CreateContactModal/view";
import { useGetGroupApi, useRemoveContactsApi } from "src/api/contacts";
import useConfirmationDialog from "src/components/ConfirmationDialog/view";
import { useGetCustomFieldsApi } from "src/api/customFields";
import { Column } from "./types";
import {
  MenuItem,
  Checkbox,
  ListItemText,
  Menu,
  IconButton,
  Box,
  CircularProgress,
} from "@mui/material";
import { DotsThreeVertical } from "phosphor-react";
import { GetContactsParams } from "../../api/contacts/types";
import SearchInput from "../../components/SearchInput/view";
import SortIcon from "../../components/SortIcon/view";
import { useHandleSelectedCompany } from "src/hooks/useHandleSelectedCompany";

function GroupContacts() {
  const [page, setPage] = useState(0);
  const tableRef = useRef<HTMLTableElement>(null);
  const [sortField, setSortField] = useState<string>("dateAdded");
  const [sortDir, setSortDir] = useState<"asc" | "desc">("desc");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [contacts, setContacts] = useState<{
    count: number;
    items: Contact[] | null;
  }>({ count: 0, items: null });
  const [group, setGroup] = useState<Group | undefined>();
  const [anchorElColumnsMenu, setAnchorElColumnsMenu] =
    useState<null | HTMLElement>(null);
  const [customFields, setCustomFields] = useState<CustomField[] | undefined>();
  const getContacts = useGetContactsApi();
  const getGroup = useGetGroupApi();
  const deleteContact = useDeleteContactApi();
  const getCustomFields = useGetCustomFieldsApi();
  const [showCreateContactModal, setCreateContactModal] = useState(false);
  const [loadingContacts, setLoadingContacts] = useState<boolean>(false);
  const [editableContact, setEditableContact] = useState<Contact | undefined>();
  const selectedCompanyId: string | undefined = useSelector(selectedCompany);
  useHandleSelectedCompany();
  const [columns, setColumns] = useState<Column[]>([
    {
      metadataId: null,
      id: "name",
      label: "Name",
      minWidth: 80,
      isSelected: true,
    },
    {
      metadataId: null,
      id: "phoneNumber",
      label: "Phone Number",
      minWidth: 100,
      isSelected: true,
    },
    {
      metadataId: null,
      id: "status",
      label: "Status",
      minWidth: 100,
      isSelected: true,
    },
    {
      metadataId: null,
      id: "email",
      label: "Email",
      minWidth: 110,
      isSelected: true,
    },
    {
      metadataId: null,
      id: "birthdate",
      label: "Birthdate",
      minWidth: 90,
      isSelected: true,
    },
    {
      metadataId: null,
      id: "timeZone",
      label: "Timezone",
      minWidth: 100,
      isSelected: true,
    },
    {
      metadataId: null,
      id: "optInType",
      label: "Opt In Type",
      minWidth: 100,
      isSelected: false,
    },
  ]);

  const getLeftStickyStyle = (left: number) => {
    const stickyStyle = {
      position: "sticky",
      "z-index": "9",
      left: left,
      background: "#fff",
    };
    return stickyStyle;
  };

  const getRightStickyStyle = () => {
    const stickyStyle = {
      position: "sticky",
      "z-index": "9",
      right: 0,
      paddingLeft: 0,
      minWidth: "100px !important",
      background: "#fff",
    };
    return stickyStyle;
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    tableRef?.current?.scrollIntoView();
  };
  const { groupId } = useParams();
  const removeContacts = useRemoveContactsApi();
  const { openDialog, ConfirmationDialog } = useConfirmationDialog();

  const [showCreateGroupModal, setSetCreateGroupModal] = useState(false);

  const refreshContacts = (sortByContactAddedDate = false) => {
    setLoadingContacts(true);
    window.setTimeout(() => {
      if (selectedCompanyId) {
        const reqQuery: GetContactsParams = {
          companyId: selectedCompanyId,
          limit: itemsPerPage,
          groups: [groupId].toString(),
          offset: page * itemsPerPage,
          sort: sortByContactAddedDate
            ? "date_added_desc"
            : `${getSortFieldForApi(sortField)}_${sortDir}`,
        };
        if (searchQuery) {
          reqQuery.query = searchQuery;
        }
        getContacts(reqQuery)
          .then(({ data }) => {
            setContacts(data);
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            setLoadingContacts(false);
          });
      }
    }, 500);
  };

  const mapSortPropsToApi: Record<string, string> = {
    name: "name", // pseudo property just for sorting
    firstName: "first_name",
    lastName: "last_name",
    email: "email",
    phoneNumber: "phone_number",
    birthdate: "birthdate",
    status: "valid_status",
    optInDate: "opt_in_date",
    optInType: "opt_in_type",
    optOutDate: "opt_out_date",
    optOutType: "opt_out_type",
    createdBy: "created_by",
    createdAt: "date_created",
    updatedAt: "date_updated",
    dateAdded: "date_added",
  };

  const isFieldSortable = (fieldName: string) =>
    Object.prototype.hasOwnProperty.call(mapSortPropsToApi, fieldName);

  const getSortFieldForApi = (fieldName: string) => {
    return mapSortPropsToApi[fieldName];
  };

  const swapSortDirection = (dir?: "asc" | "desc") => {
    if (dir) {
      setSortDir(dir);
    } else {
      setSortDir(sortDir == "asc" ? "desc" : "asc");
    }
  };

  const changeSortField = (fieldName: string) => {
    if (sortField == fieldName) {
      swapSortDirection();
    } else {
      swapSortDirection("desc");
    }
    setSortField(fieldName);
  };

  const handleSearchQuery = (text: string) => {
    setSearchQuery(text);
  };

  useEffect(() => {
    if (selectedCompanyId) {
      const customFieldsColumns: Column[] = [
        {
          metadataId: null,
          id: "actions",
          label: "Actions",
          minWidth: 30,
          align: "left",
          isSelected: true,
        },
      ];

      getCustomFields({
        companyId: selectedCompanyId!,
        offset: 0,
        limit: customFieldsLimit,
      })
        .then((customFields) => {
          if (customFields.data.items) {
            setCustomFields(customFields.data.items);
            customFields.data.items.map((c) => {
              customFieldsColumns.unshift({
                metadataId: c.id,
                id: "customField",
                customFieldName: c.name,
                label: c.description,
                minWidth: 80,
                isSelected: false,
              });
            });
          }
        })
        .finally(() => {
          const newColumns = [...columns, ...customFieldsColumns];
          setColumns(newColumns);
        });
    }
  }, [selectedCompanyId]);

  const refreshGroup = () => {
    selectedCompanyId &&
      groupId &&
      getGroup(groupId, { companyId: selectedCompanyId }).then(({ data }) => {
        setGroup(data);
        setSetCreateGroupModal(true);
      });
  };

  useEffect(() => {
    selectedCompanyId &&
      groupId &&
      getGroup(groupId, { companyId: selectedCompanyId }).then(({ data }) => {
        setGroup(data);
      });
  }, [groupId, selectedCompanyId]);

  const formatTableRows = (contacts: Contact[]) => {
    return contacts.map(
      ({
        id,
        email,
        firstName,
        lastName,
        phoneNumber,
        birthdate,
        timeZone,
        validStatus,
        optInType,
        optOutDate,
        metadata,
      }) => {
        return {
          id,
          name:
            firstName && lastName
              ? `${firstName} ${lastName}`
              : firstName
              ? firstName
              : lastName,
          email,
          phoneNumber,
          status: getContactStatus(validStatus, optOutDate),
          optInType: optInType,
          optOutDate: optOutDate,
          birthdate: birthdate ? formatDateWithoutTimezone(birthdate) : "-",
          timeZone,
          metadata,
          actions: "edit/delete",
        };
      }
    );
  };

  useEffect(() => {
    refreshContacts();
  }, [page, selectedCompanyId, sortDir, sortField, searchQuery]);

  useEffect(() => {
    setPage(0);
    tableRef?.current?.scrollIntoView();
  }, [sortDir, sortField, searchQuery]);

  const handleContactEdit = async (contactId: string) => {
    const contact = contacts?.items?.find(({ id }) => id === contactId);
    const customFields = await getCustomFields({
      companyId: selectedCompanyId!,
      offset: 0,
      limit: customFieldsLimit,
    });
    const metadata = customFields.data.items.map((customField) => {
      const field = contact?.metadata.find(
        (m) => m.metadataId === customField.id
      );
      if (field) {
        return field;
      } else {
        return {
          contactId: contact!.id,
          metadataId: customField.id,
          type: customField.type,
          name: customField.name,
          description: customField.description,
          value: "",
        } as ContactCustomField;
      }
    });

    contact!.metadata = metadata;
    setEditableContact(contact);
    setCreateContactModal(true);
  };

  const handleOpenSelectColumnsMenu = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorElColumnsMenu(event.currentTarget);
  };

  const handleCloseSelectColumnsMenu = () => {
    setAnchorElColumnsMenu(null);
  };

  const handleContactDelete = (contactId: string) => {
    if (group?.type === "default") {
      selectedCompanyId &&
        deleteContact(contactId, selectedCompanyId).then(() => {
          refreshContacts();
        });
    } else {
      selectedCompanyId &&
        group?.id &&
        removeContacts(
          [contactId],
          group?.id,
          selectedCompanyId as string
        ).then(() => {
          refreshContacts();
        });
    }
  };

  const getContactStatus = (status: string, optOutDate: string | null) => {
    if (status == "invalid") {
      return "Invalid";
    }
    if (optOutDate) {
      return "Opted Out";
    }
    return "Active";
  };

  const formatPageHeader = (groupName: string | undefined) => {
    const style = { textDecoration: "none", color: "#254597" };
    if (groupName && groupName.toLowerCase() === "all contacts") {
      return (
        <>
          <Link to="/Groups" style={style}>
            Contact Groups
          </Link>{" "}
          / All Contacts
        </>
      );
    }

    return (
      <>
        <Link to="/Groups" style={style}>
          {groupName}
        </Link>{" "}
        / Contacts
      </>
    );
  };

  return (
    <MainWrapper>
      <PageHeader>{formatPageHeader(group?.name)}</PageHeader>
      <HeaderContainer>
        <SearchInput handleSearch={handleSearchQuery} />
        <Box>
          <Button
            variant="contained"
            onClick={refreshGroup}
            disabled={group?.type === "default"}
          >
            Edit Group
          </Button>
        </Box>
      </HeaderContainer>
      {showCreateGroupModal && (
        <CreateGroupModal
          show={showCreateGroupModal}
          setShowModal={setSetCreateGroupModal}
          refreshGroups={refreshContacts}
          groupData={group}
        />
      )}
      {showCreateContactModal && (
        <CreateContactModal
          show={showCreateContactModal}
          setShowModal={setCreateContactModal}
          refresh={refreshContacts}
          groupData={group}
          customFields={customFields}
          contactData={editableContact}
        />
      )}

      <Paper sx={{ width: "100%", overflow: "hidden", marginTop: "20px" }}>
        <TableContainer sx={{ height: "calc(100vh - 250px)" }}>
          {loadingContacts && (
            <Box width='100%' height='100%' display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          )}
          {!loadingContacts && (
            <Table ref={tableRef} aria-label="sticky table">
              <TableHead sx={{ position: "sticky", top: 0, zIndex: 100 }}>
                <TableRow>
                  {columns.map((column, index) => {
                    if (!column.isSelected) {
                      return null;
                    }
                    return (
                      <TableCell
                        sx={
                          index < 2
                            ? getLeftStickyStyle(index === 0 ? 0 : 112)
                            : column.id === "actions"
                            ? getRightStickyStyle()
                            : { position: "sticky" }
                        }
                        key={index}
                        align={column.align}
                        style={{
                          minWidth: column.metadataId
                            ? "200px"
                            : column.minWidth,
                          background: "#254597",
                          color: "#fff",
                          cursor: isFieldSortable(column.id)
                            ? "pointer"
                            : "initial",
                        }}
                        onClick={() =>
                          isFieldSortable(column.id)
                            ? changeSortField(column.id)
                            : {}
                        }
                      >
                        <Box display="flex" justifyContent="space-between">
                          <Box>
                            {column.label}
                            {sortField == column.id && (
                              <SortIcon dir={sortDir} />
                            )}
                          </Box>
                          {column.id === "actions" && (
                            <Box>
                              <IconButton
                                onClick={handleOpenSelectColumnsMenu}
                                sx={{ p: 0 }}
                              >
                                <DotsThreeVertical weight="bold" color="#fff" />
                              </IconButton>
                              <Menu
                                id="columns-multiple-checkbox"
                                anchorEl={anchorElColumnsMenu}
                                open={Boolean(anchorElColumnsMenu)}
                                onClose={handleCloseSelectColumnsMenu}
                              >
                                {columns.map((column, index) => (
                                  <MenuItem
                                    disabled={[
                                      "name",
                                      "phoneNumber",
                                      "actions",
                                    ].includes(column.id)}
                                    key={column.metadataId || column.id}
                                    value={column.metadataId || column.id}
                                    onClick={() => {
                                      const newSelectedColumns = columns.map(
                                        (c, i) => {
                                          if (i === index) {
                                            return {
                                              ...c,
                                              isSelected: !c.isSelected,
                                            };
                                          } else {
                                            return c;
                                          }
                                        }
                                      );
                                      setColumns(newSelectedColumns);
                                    }}
                                  >
                                    <Box
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center"
                                    >
                                      <Checkbox
                                        sx={{ paddingLeft: 0 }}
                                        value={
                                          column.metadataId
                                            ? column.metadataId
                                            : column.id
                                        }
                                        checked={column.isSelected}
                                      />
                                      <ListItemText primary={column.label} />
                                    </Box>
                                  </MenuItem>
                                ))}
                              </Menu>
                            </Box>
                          )}
                        </Box>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody sx={{ position: "sticky" }}>
                {contacts?.items &&
                  formatTableRows(contacts?.items).map((row, index) => {
                    return (
                      <TableRow key={index} sx={{ cursor: "pointer" }}>
                        {columns.map((column: Column, index) => {
                          if (!column.isSelected) {
                            return null;
                          }
                          const value = column.metadataId
                            ? row.metadata.find(
                                (m) => m.metadataId === column.metadataId
                              )?.value
                            : column.id !== "customField"
                            ? row[column.id]
                            : "";
                          return (
                            <TableCell
                              sx={
                                index < 2
                                  ? getLeftStickyStyle(index === 0 ? 0 : 112)
                                  : column.id === "actions"
                                  ? getRightStickyStyle()
                                  : { position: "relative" }
                              }
                              style={{
                                minWidth: column.metadataId
                                  ? "200px"
                                  : column.minWidth,
                              }}
                              key={
                                column.metadataId
                                  ? column.metadataId
                                  : column.id
                              }
                              align={column.align}
                            >
                              {column.id === "actions" ? (
                                <>
                                  <Button
                                    sx={{ minWidth: "10px;", paddingLeft: 0 }}
                                    onClick={() => handleContactEdit(row.id)}
                                  >
                                    <EditIcon />
                                  </Button>
                                  {group && group.type !== "dynamic" && (
                                    <Button
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        openDialog({
                                          title: `Remove ${
                                            row.name
                                              ? `"${row.name.trim()}"`
                                              : `"${row.phoneNumber}"`
                                          }?`,
                                          content: `Are you sure you want to remove this contact ${
                                            group?.type === "custom"
                                              ? "from this group"
                                              : ""
                                          }?`,
                                          onConfirm: () => {
                                            handleContactDelete(row.id);
                                          },
                                        });
                                      }}
                                    >
                                      {" "}
                                      <DeleteIcon />
                                    </Button>
                                  )}
                                </>
                              ) : (
                                value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        {!loadingContacts && (
          <TablePagination
            component="div"
            count={contacts.count}
            rowsPerPageOptions={[-1]}
            rowsPerPage={itemsPerPage}
            page={page}
            onPageChange={handleChangePage}
          />
        )}
      </Paper>
      <ConfirmationDialog />
    </MainWrapper>
  );
}

export default GroupContacts;
