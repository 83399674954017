import { useCallback } from "react";
import apiClient from "../axiosClient";
import {
  DeleteMessageTemplateApiParams,
  GetMessageTemplateApiParams,
  GetMessageTemplatesApiParams,
} from "./types";

export function useGetMessageTemplatesApi(): GetMessageTemplatesApiParams {
  return useCallback((params) => {
    params.sort = params.sort?.replace('dateUpdated', 'date_updated').replace('dateCreated', 'date_created')
    return apiClient.get(`/message-templates`, { params });
  }, []);
}

export function useGetMessageTemplateApi(): GetMessageTemplateApiParams {
  return useCallback(
    (messageTemplateId, companyId) =>
      apiClient.get(`/message-templates/${messageTemplateId}`, {
        params: { companyId },
      }),
    []
  );
}

export function useDeleteMessageTemplateApi(): DeleteMessageTemplateApiParams {
  return useCallback(
    (messageTemplateId, companyId) =>
      apiClient.delete(`message-templates/${messageTemplateId}`, {
        params: { companyId },
      }),
    []
  );
}

export function useCreateMessageTemplateApi() {
  return useCallback(
    (name: string, text: string, companyId: string) =>
      apiClient.post(
        `message-templates`,
        { name, text },
        { params: { companyId } }
      ),
    []
  );
}

export function useUpdateMessageTemplateApi() {
  return useCallback(
    (
      name: string,
      text: string,
      messageTemplateId: string,
      companyId: string
    ) =>
      apiClient.put(
        `message-templates/${messageTemplateId}`,
        { name, text },
        { params: { companyId } }
      ),
    []
  );
}
